import elementId from 'utils/helpers/elementId/elementIds'

export const upperSectionNavigationTab = [
  {
    label: 'Warna',
    value: 'warna',
    testid: elementId.Tab + 'warna',
  },
  {
    label: 'Eksterior',
    value: 'eksterior',
    testid: elementId.Tab + 'eksterior',
  },
  {
    label: 'Interior',
    value: 'interior',
    testid: elementId.Tab + 'interior',
  },
  {
    label: 'Video',
    value: 'video',
    testid: elementId.Tab + 'video',
  },
  {
    label: '360º Eksterior',
    value: '360-eksterior',
    testid: elementId.Tab + '360-exterior',
  },
  {
    label: '360º Interior',
    value: '360-interior',
    testid: elementId.Tab + '360-interior',
  },
]

export const lowerSectionNavigationTab = [
  {
    label: 'Ringkasan',
    value: 'ringkasan',
    testid: elementId.Tab + 'ringkasan',
  },
  {
    label: 'Spesifikasi',
    value: 'spesifikasi',
    testid: elementId.Tab + 'spesifikasi',
  },
  {
    label: 'Harga',
    value: 'harga',
    testid: elementId.Tab + 'harga',
  },
  {
    label: 'Kredit',
    value: 'kredit',
    testid: elementId.Tab + 'kredit',
  },
]

export const specificationTab = [
  {
    label: 'Dimensi',
    value: 'dimension',
    testid: elementId.Tab + 'Dimensi',
  },
  {
    label: 'Mesin & Transmisi',
    value: 'machine',
    testid: elementId.Tab + 'Mesin & Transmisi',
  },
  {
    label: 'Chassis',
    value: 'chassis',
    testid: elementId.Tab + 'Chassis',
  },
  {
    label: 'Interior',
    value: 'interior',
    testid: elementId.Tab + 'Interior',
  },
  {
    label: 'Fitur Keamanan',
    value: 'safety',
    testid: elementId.Tab + 'Fitur Keamanan',
  },
]
