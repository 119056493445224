import React from 'react'
import { PropsIcon } from 'utils/types'

export const IconThumbsUp = ({
  width = 14,
  height = 15,
  color = 'white',
}: PropsIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 15"
      fill="none"
    >
      <path
        d="M8.56953 1.2257C9.28047 1.36789 9.74258 2.05968 9.60039 2.77062L9.5375 3.08234C9.39258 3.81242 9.12461 4.50695 8.75 5.13859H12.6875C13.4121 5.13859 14 5.72648 14 6.45109C14 6.95695 13.7129 7.39718 13.2918 7.61593C13.5898 7.85656 13.7812 8.2257 13.7812 8.63859C13.7812 9.27843 13.3219 9.81164 12.7176 9.92648C12.8379 10.1261 12.9062 10.3585 12.9062 10.6073C12.9062 11.1898 12.5262 11.6847 12.0012 11.8542C12.0203 11.9445 12.0312 12.0402 12.0312 12.1386C12.0312 12.8632 11.4434 13.4511 10.7188 13.4511H8.05273C7.5332 13.4511 7.02734 13.298 6.59531 13.0109L5.54258 12.3081C4.8125 11.8214 4.375 11.0011 4.375 10.1234V7.08273C4.375 6.28429 4.73867 5.53234 5.35938 5.03195L5.56172 4.87062C6.28633 4.29093 6.78125 3.47609 6.96172 2.56828L7.02461 2.25656C7.1668 1.54562 7.85859 1.08351 8.56953 1.2257ZM0.875 5.57609H2.625C3.10898 5.57609 3.5 5.96711 3.5 6.45109V12.5761C3.5 13.0601 3.10898 13.4511 2.625 13.4511H0.875C0.391016 13.4511 0 13.0601 0 12.5761V6.45109C0 5.96711 0.391016 5.57609 0.875 5.57609Z"
        fill={color}
      />
    </svg>
  )
}
