import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconChevronDown: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#05256E',
  alt,
  className,
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>{alt}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.53083 14.5212C9.91267 14.1463 10.5318 14.1463 10.9136 14.5212L20 23.4424L29.0864 14.5212C29.4682 14.1463 30.0873 14.1463 30.4692 14.5212C30.851 14.8961 30.851 15.5039 30.4692 15.8788L20.6914 25.4788C20.3095 25.8537 19.6905 25.8537 19.3086 25.4788L9.53083 15.8788C9.14898 15.5039 9.14898 14.8961 9.53083 14.5212Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.35798 14.3515C9.83529 13.8828 10.6092 13.8828 11.0865 14.3515L20 23.1029L28.9135 14.3515C29.3908 13.8828 30.1647 13.8828 30.642 14.3515C31.1193 14.8201 31.1193 15.5799 30.642 16.0485L20.8642 25.6485C20.3869 26.1172 19.6131 26.1172 19.1358 25.6485L9.35798 16.0485C8.88067 15.5799 8.88067 14.8201 9.35798 14.3515ZM10.7408 14.6909C10.4544 14.4097 9.99006 14.4097 9.70368 14.6909C9.41729 14.9721 9.41729 15.4279 9.70368 15.7091L19.4815 25.3091C19.7678 25.5903 20.2322 25.5903 20.5185 25.3091L30.2963 15.7091C30.5827 15.4279 30.5827 14.9721 30.2963 14.6909C30.0099 14.4097 29.5456 14.4097 29.2592 14.6909L20.1728 23.6121C20.127 23.6571 20.0648 23.6824 20 23.6824C19.9352 23.6824 19.873 23.6571 19.8272 23.6121L10.7408 14.6909Z"
        fill={color}
      />
    </svg>
  )
}
