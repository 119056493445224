import { RegExLongAlphabetAndSpaces, RegexName } from 'utils/handler/regex'

export const DOT = '.'
export const COMMA = ','

export const filterSpecialChar = (input: string) => {
  return input.replace(/[*()\\?+[\]]+/g, '')
}
export const filterNonDigitCharacters = (input: string) => {
  const nonDigits = new RegExp(/[^\d]+/g)
  return input.replace(nonDigits, '')
}
export const filterNumbers = (input: string) => {
  const numbers = new RegExp(/[0-9]+$/g)
  return input.replace(numbers, '')
}

const capitalizeSingleWord = (str: string) => {
  return (
    str.charAt(0).toUpperCase() +
    str.substring(1, str.length).toLocaleLowerCase()
  )
}
const capitalize = (str: string, character: ' ' | '/' | '-') => {
  const arr = str.split(character)
  return arr
    .map((item) => {
      return capitalizeSingleWord(item)
    })
    .join(character)
}
export const capitalizeWords = (str: string) => {
  const arr = str.split(' ')
  return arr
    .map((item) => {
      const array1 = item.split('/')
      return array1
        .map((item2) => {
          return capitalize(item2, '-')
        })
        .join('/')
    })
    .join(' ')
}

export const addSeparator = (
  value: string,
  separator = DOT,
  length = 3,
): string => {
  const tmp = value?.replace(separator, '')
  const reg = new RegExp('\\B(?=(\\d{' + length + '})+(?!\\d))', 'g')
  return tmp?.replace(reg, separator)
}

export const isAmountValid = (value: string): boolean => {
  return /(^[1-9](\d{6,8})$)/.test(value)
}

export const prefixWithZero = (originalNumber: number | string) => {
  return `0${originalNumber}`.slice(-2)
}

export const capitalizeFirstLetter = (str: string) => {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const removeFirstWordFromString = (value: string) => {
  const trimmed = value.trim()
  return trimmed.trim().substring(trimmed.indexOf(' ') + 1)
}

export const replaceIndex0 = (payload: string, newChar: string) => {
  return newChar + payload.slice(1)
}

export const truncateString = (str: string, num: number) => {
  if (str.length > num) {
    return str.slice(0, num) + '...'
  } else {
    return str
  }
}

export const filterNonLetterAndSpaceAndApostropheAndDot = (value: string) => {
  const matched = new RegExp(/([^a-zA-Z '.]+)/g)
  return value?.replace(matched, '')
}

export const filterLetterName = (value: string) => {
  const matched = new RegExp(/([^a-zA-Z '.,]+)/g)
  return value?.replace(matched, '')
}
