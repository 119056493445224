import LogoToyota from '/public/revamp/icon/logo-toyota.webp'
import LogoDaihatsu from '/public/revamp/icon/logo-daihatsu.webp'
import Isuzu from '/public/revamp/icon/logo-isuzu.webp'
import LogoBmw from '/public/revamp/icon/logo-bmw.webp'
import Peugeot from '/public/revamp/icon/logo-peugeot.webp'
import LogoHyundai from '/public/revamp/icon/logo-hyundai.webp'
import Image from 'next/image'
import styles from '/styles/components/organisms/lpCarRecommendations.module.scss'

interface TabItemData {
  label: string | JSX.Element
  value: string
  testid?: string
}
const brandList: TabItemData[] = [
  {
    label: 'Semua',
    value: '',
  },
  {
    label: (
      <div className={styles.tabBrand}>
        <Image
          alt="Toyota Car Recommendation"
          src={LogoToyota}
          style={{ width: 21, height: 18 }}
          priority
        />
        Toyota
      </div>
    ),
    value: 'Toyota',
  },
  {
    label: (
      <div className={styles.tabBrand}>
        <Image
          alt="Daihatsu Car Recommendation"
          src={LogoDaihatsu}
          style={{ width: 21.6, height: 15 }}
          priority
        />
        Daihatsu
      </div>
    ),
    value: 'Daihatsu',
  },
  {
    label: (
      <div className={styles.tabBrand}>
        <Image
          alt="Isuzu Car Recommendation"
          src={Isuzu}
          style={{ width: 21.6, height: 7.2 }}
          priority
        />
        Isuzu
      </div>
    ),
    value: 'Isuzu',
  },
  {
    label: (
      <div className={styles.tabBrand}>
        <Image
          alt="BMW Car Recommendation"
          src={LogoBmw}
          style={{ width: 19.2, height: 19.2 }}
          priority
        />
        BMW
      </div>
    ),
    value: 'BMW',
  },
  // {
  //   label: (
  //     <div className={styles.tabBrand}>
  //       <Image
  //         alt="Peugeot Car Recommendation"
  //         src={Peugeot}
  //         style={{ width: 17.49, height: 19.2 }}
  //         priority
  //       />
  //       Peugeot
  //     </div>
  //   ),
  //   value: 'Peugeot',
  // },
  {
    label: (
      <div className={styles.tabBrand}>
        <Image
          alt="Hyundai Car Recommendation"
          src={LogoHyundai}
          style={{ width: 20, height: 20 }}
          width={20}
          height={20}
          priority
        />
        Hyundai
      </div>
    ),
    value: 'Hyundai',
  },
]

export default brandList
