import elementId from 'utils/helpers/elementId/elementIds'
import React, { useMemo } from 'react'
import {
  OTOVariantListUrl,
  loanCalculatorDefaultUrl,
  variantListUrl,
} from 'utils/helpers/routes'
import {
  generateAllPromosForBadge,
  getLowestInstallment,
} from 'utils/carModelUtils/carModelUtils'
import { replacePriceSeparatorByLocalization } from 'utils/handler/rupiah'
import { Button, CardShadow } from 'components/atoms'
import { ButtonSize, ButtonVersion } from 'components/atoms/button'
import { LabelPromo } from 'components/molecules'
import styles from '../../../styles/components/organisms/alternativeCarCard.module.scss'
import {
  trackCarBrandRecomItemClick,
  trackLCCarRecommendationClick,
} from 'services/amplitude/seva20Tracking'
import { LanguageCode, LocalStorageKey, SessionStorageKey } from 'utils/enum'
import { Location } from 'utils/types'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { useRouter } from 'next/router'
import Image from 'next/image'
import {
  PreviousButton,
  defineRouteName,
  saveDataForCountlyTrackerPageViewPDP,
} from 'utils/navigate'
import { trackEventCountly } from 'services/countly/countly'
import { CountlyEventNames } from 'services/countly/eventNames'
import {
  CarRecommendation,
  CarVariantListPageUrlParams,
  trackDataCarType,
} from 'utils/types/utils'
import {
  getSessionStorage,
  saveSessionStorage,
} from 'utils/handler/sessionStorage'
import { removeCarBrand } from 'utils/handler/removeCarBrand'
import { useOnceClick } from 'utils/hooks/useOnceClick'
import clsx from 'clsx'
import {
  getRecommendationsInstallmentHighestCalculatorAsNumber,
  getRecommendationsLowestInstallmentMainAsNumber,
} from 'utils/handler/carRecommendation'
import { getPromoDifferenceInPercentage } from 'utils/handler/loanCalculator'
import Link from 'next/link'
const CarSkeleton = '/revamp/illustration/car-skeleton.webp'

type AlternativeCarCardProps = {
  recommendation: CarRecommendation
  onClickLabel: () => void
  children?: React.ReactNode
  label?: React.ReactNode
  pageOrigination?: string
  carBrand?: string // for value brand after calculated
  carModel?: string // for value model after calculated
  isOTO?: boolean
  isDealer?: boolean
  isDisableNavigationToPdp?: boolean
  isGiias?: boolean
  recommendationList?: CarRecommendation[]
}

export const AlternativeCarCard = ({
  recommendation,
  onClickLabel,
  children,
  label,
  pageOrigination,
  carBrand,
  carModel,
  isOTO = false,
  isDealer,
  isDisableNavigationToPdp,
  isGiias,
  recommendationList,
}: AlternativeCarCardProps) => {
  const router = useRouter()
  const [cityOtr] = useLocalStorage<Location | null>(
    LocalStorageKey.CityOtr,
    null,
  )
  const dataCar: trackDataCarType | null = getSessionStorage(
    SessionStorageKey.PreviousCarDataBeforeLogin,
  )

  const liveOtr = useMemo(() => {
    if (recommendation.lowestMonthlyInstallment === undefined) {
      return true // if object isnt valid, treat as OTR is live
    }

    return (
      recommendation.lowestMonthlyInstallment &&
      recommendation.lowestMonthlyInstallment > 0
    )
  }, [recommendation])

  const { onClickOnce } = useOnceClick()
  const brand = router.query.brand as string
  const model = router.query.model as string
  const getCityUrl = () => {
    if (cityOtr) return `/${cityOtr.cityName.toLowerCase().replace(' ', '-')}`
    else return '/'
  }
  const detailCarRoute = (isOTO ? OTOVariantListUrl : variantListUrl)
    .replace(
      ':brand/:model',
      (
        recommendation.brand +
        '/' +
        recommendation.model.replace(/ +/g, '-') +
        getCityUrl()
      )
        .replace(/ +/g, '')
        .toLowerCase(),
    )
    .replace(':tab?', '')

  const getValueBrandAndModel = (value: string) => {
    if (value && value.length !== 0 && value.includes('-')) {
      return value
        .replaceAll('-', ' ')
        .toLowerCase()
        .split(' ')
        .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
    } else if (value && value.length !== 0) {
      return value
    } else {
      return 'Null'
    }
  }
  const getValueBrand = (value: string) => {
    if (value) {
      return value
        .replaceAll('-', ' ')
        .toLowerCase()
        .split(' ')
        .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
    } else {
      return 'Null'
    }
  }

  const trackCountlyCarRecommendation = () => {
    const CAR_BRAND = carBrand
      ? getValueBrandAndModel(carBrand)
      : getValueBrand(brand)
    const CAR_MODEL = carModel
      ? getValueBrandAndModel(removeCarBrand(carModel))
      : getValueBrandAndModel(model)

    if (pageOrigination?.toLowerCase().includes('pdp')) {
      trackEventCountly(CountlyEventNames.WEB_PDP_CAR_RECOMMENDATION_CLICK, {
        CAR_BRAND: recommendation.brand,
        CAR_MODEL: recommendation.model,
      })

      if (!pageOrigination?.toLowerCase().includes('kredit')) return
    }
    trackEventCountly(CountlyEventNames.WEB_CAR_RECOMMENDATION_CLICK, {
      PAGE_ORIGINATION: pageOrigination ? pageOrigination : 'PLP - Empty Page',
      PELUANG_KREDIT_BADGE:
        !label ||
        pageOrigination?.toLowerCase() === 'homepage' ||
        pageOrigination === 'Success Page'
          ? 'Null'
          : 'Mudah disetujui',

      CAR_BRAND:
        pageOrigination?.toLowerCase() === 'homepage' ||
        pageOrigination === 'Success Page' ||
        !pageOrigination?.toLowerCase().includes('pdp')
          ? 'Null'
          : CAR_BRAND,
      CAR_MODEL:
        pageOrigination?.toLowerCase() === 'homepage' ||
        pageOrigination === 'Success Page'
          ? 'Null'
          : CAR_MODEL,
      CAR_BRAND_RECOMMENDATION: recommendation.brand,
      CAR_MODEL_RECOMMENDATION: recommendation.model,
      PAGE_DIRECTION_URL:
        'https://' + window.location.hostname + detailCarRoute,
      TENOR_OPTION: dataCar?.TENOR_OPTION,
      TENOR_RESULT:
        dataCar?.TENOR_RESULT && dataCar?.TENOR_RESULT === 'Green'
          ? 'Mudah disetujui'
          : dataCar?.TENOR_RESULT && dataCar?.TENOR_RESULT === 'Red'
          ? 'Sulit disetujui'
          : 'Null',
    })
  }
  const trackCarRecommendation = () => {
    if (location.pathname.includes(loanCalculatorDefaultUrl)) {
      const lowestInstallment = getLowestInstallment(recommendation.variants)
      const formatLowestInstallment = replacePriceSeparatorByLocalization(
        lowestInstallment,
        LanguageCode.id,
      )
      trackLCCarRecommendationClick({
        Car_Brand: recommendation.brand,
        Car_Model: recommendation.model,
        City: cityOtr?.cityName,
        Monthly_Installment: `Rp${formatLowestInstallment}`,
        Page_Origination: window.location.href,
      })
    } else {
      trackCarBrandRecomItemClick({
        Car_Brand: recommendation.brand,
        Car_Model: recommendation.model,
      })
    }
  }

  const navigateToPDP = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    if (isDisableNavigationToPdp) return

    trackCarRecommendation()
    trackCountlyCarRecommendation()
    const dataCarTemp = {
      ...dataCar,
      PELUANG_KREDIT_BADGE: 'Mudah disetujui',
    }
    saveSessionStorage(
      SessionStorageKey.PreviousCarDataBeforeLogin,
      JSON.stringify(dataCarTemp),
    )
    if (window.location.pathname.includes('kalkulator-kredit')) {
      saveDataForCountlyTrackerPageViewPDP(
        PreviousButton.CarRecommendation,
        defineRouteName(window.location.pathname + window.location.search),
      )
    } else {
      saveDataForCountlyTrackerPageViewPDP(PreviousButton.CarRecommendation)
    }
    if (e.button === 0 && e.ctrlKey) {
      window.open(detailCarRoute, '_blank')
    } else {
      window.location.href = detailCarRoute
    }
  }
  const navigateToPDPMiddleRightClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    if (isDisableNavigationToPdp) return

    if (e.button === 1 || e.button === 2) {
      trackCountlyCarRecommendation()
      const dataCarTemp = {
        ...dataCar,
        PELUANG_KREDIT_BADGE: 'Mudah disetujui',
      }
      saveSessionStorage(
        SessionStorageKey.PreviousCarDataBeforeLogin,
        JSON.stringify(dataCarTemp),
      )
      if (window.location.pathname.includes('kalkulator-kredit')) {
        saveDataForCountlyTrackerPageViewPDP(
          PreviousButton.CarRecommendation,
          defineRouteName(window.location.pathname + window.location.search),
        )
      } else {
        saveDataForCountlyTrackerPageViewPDP(PreviousButton.CarRecommendation)
      }
    }
  }

  const lowestInstallmentMainAsNumber = useMemo(() => {
    return getRecommendationsLowestInstallmentMainAsNumber(recommendation)
  }, [recommendation])

  const lowestInstallmentMainAsWording = useMemo(() => {
    return replacePriceSeparatorByLocalization(
      lowestInstallmentMainAsNumber,
      LanguageCode.id,
    )
  }, [lowestInstallmentMainAsNumber])

  const lowestInstallmentStrikeThroughAsNumber = useMemo(() => {
    return getRecommendationsInstallmentHighestCalculatorAsNumber(
      recommendation,
    )
  }, [recommendation])

  const additionalSpacing =
    recommendationList &&
    !!recommendationList.some(
      (item) => item.variants[0].calculator?.lowestCalculator,
    )
      ? true
      : recommendationList &&
        recommendationList.every((car) => !car.hasLiveVariant) &&
        recommendationList[0].lowestDpAmount !== undefined
      ? true
      : false

  const lowestInstallmentStrikeThroughAsWording = useMemo(() => {
    return replacePriceSeparatorByLocalization(
      lowestInstallmentStrikeThroughAsNumber || 0,
      LanguageCode.id,
    )
  }, [lowestInstallmentStrikeThroughAsNumber])

  const lowestInstallmentPercentageDifference = useMemo(() => {
    if (
      !!lowestInstallmentStrikeThroughAsNumber &&
      lowestInstallmentStrikeThroughAsNumber !== 0
    ) {
      return getPromoDifferenceInPercentage(
        lowestInstallmentMainAsNumber,
        lowestInstallmentStrikeThroughAsNumber,
      )
    } else {
      return null
    }
  }, [lowestInstallmentMainAsNumber, lowestInstallmentStrikeThroughAsNumber])

  const onClickSeeDetail = () => {
    onClickOnce(() => {
      trackEventCountly(CountlyEventNames.WEB_CAR_RECOMMENDATION_CTA_CLICK, {
        PAGE_ORIGINATION: 'PLP - Empty Page',
        CAR_BRAND: 'Null',
        CAR_MODEL: 'Null',
        CAR_BRAND_RECOMMENDATION: recommendation.brand,
        CAR_MODEL_RECOMMENDATION: recommendation.model,
        CTA_BUTTON: 'Lihat Detail',
        PAGE_DIRECTION_URL: window.location.hostname + detailCarRoute,
      })

      if (window.location.pathname.includes('kalkulator-kredit')) {
        saveDataForCountlyTrackerPageViewPDP(
          PreviousButton.CarRecommendation,
          defineRouteName(window.location.pathname + window.location.search),
        )
      } else {
        saveDataForCountlyTrackerPageViewPDP(PreviousButton.CarRecommendation)
      }

      window.location.href = detailCarRoute
    })
  }

  const renderInstallmentStrikethroughSection = () => {
    if (
      !!lowestInstallmentStrikeThroughAsNumber &&
      lowestInstallmentStrikeThroughAsNumber !== 0 &&
      lowestInstallmentStrikeThroughAsNumber > lowestInstallmentMainAsNumber
    ) {
      return (
        <div className={styles.strikeThroughWrapper}>
          <span className={styles.bodyPriceStrikethroughText}>
            Rp{lowestInstallmentStrikeThroughAsWording}/bln
          </span>
          <span className={styles.percentageDifferenceText}>
            {lowestInstallmentPercentageDifference}%
          </span>
        </div>
      )
    } else {
      return (
        <div
          className={clsx({
            [styles.spacingLine]: additionalSpacing,
          })}
        ></div>
      )
    }
  }

  return (
    <div className={styles.container}>
      <CardShadow
        className={clsx({
          [styles.cardWrapper]: true,
          [styles.noLiveOtr]: !liveOtr,
        })}
      >
        <Link
          href={detailCarRoute}
          onClick={navigateToPDP}
          className={styles.heroImg}
          onMouseDown={navigateToPDPMiddleRightClick}
        >
          <Image
            src={recommendation.images[0]}
            alt={`${recommendation.brand} ${recommendation.model}`}
            data-testid={elementId.CarRecommendation.Image}
            width={180}
            height={135}
            priority
            placeholder="blur"
            blurDataURL={CarSkeleton}
            className={styles.carImage}
          />
        </Link>
        {(label ||
          (recommendation.isPassengerCar &&
            generateAllPromosForBadge(recommendation).length > 0)) ?? (
          <LabelPromo
            className={styles.labelCard}
            onClick={onClickLabel}
            data-testid={elementId.CarRecommendation.Button.Promo}
            boldText={`${
              generateAllPromosForBadge(recommendation).length
            } promo`}
          />
        )}
        <Link
          href={detailCarRoute}
          className={styles.contentWrapper}
          onClick={navigateToPDP}
          onMouseDown={navigateToPDPMiddleRightClick}
          data-testid={elementId.CarRecommendation.BrandModel}
        >
          {isDealer ? (
            <h3 className={styles.brandModelText}>
              {recommendation.brand} {recommendation.model}
            </h3>
          ) : isGiias ? (
            <h4 className={styles.brandModelTextGiias}>
              {recommendation.brand} {recommendation.model}
            </h4>
          ) : (
            <h4 className={styles.brandModelText}>
              {recommendation.brand} {recommendation.model}
            </h4>
          )}

          <div
            className={styles.infoWrapper}
            data-testid={elementId.CarRecommendation.NominalCicilan}
          >
            <div
              className={clsx({
                [styles.detailInfoWrapper]: true,
                [styles.marginBottom20px]:
                  !liveOtr || lowestInstallmentMainAsWording === '0',
              })}
            >
              <span className={styles.smallRegular}>
                {liveOtr && lowestInstallmentMainAsWording !== '0'
                  ? 'Cicilan mulai dari'
                  : 'Harga sedang diperbaharui'}
              </span>
              {liveOtr && lowestInstallmentMainAsWording !== '0' ? (
                <>
                  {isGiias ? (
                    <span className={styles.bodyPriceTextGiias}>
                      Rp{lowestInstallmentMainAsWording}/bln
                    </span>
                  ) : (
                    <>
                      <span className={styles.bodyPriceText}>
                        Rp{lowestInstallmentMainAsWording}/bln
                      </span>
                      {renderInstallmentStrikethroughSection()}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Link>
        {children ?? (
          <Button
            version={ButtonVersion.Secondary}
            size={ButtonSize.Big}
            onClick={onClickSeeDetail}
            data-testid={elementId.CarRecommendation.Button.LihatDetail}
          >
            Lihat Detail
          </Button>
        )}
      </CardShadow>
    </div>
  )
}
