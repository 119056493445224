export enum GTMNames {
  //PLP Used Car
  WEB_USED_CAR_PLP_FILTER_BRAND_TOYOTA_CLICK = 'web_used_car_plp_filter_brand_toyota_click',
  WEB_USED_CAR_PLP_FILTER_BRAND_DAIHATSU_CLICK = 'web_used_car_plp_filter_brand_daihatsu_click',
  WEB_USED_CAR_PLP_FILTER_BRAND_HONDA_CLICK = 'web_used_car_plp_filter_brand_honda_click',
  WEB_USED_CAR_PLP_FILTER_BRAND_MITSUBISHI_CLICK = 'web_used_car_plp_filter_brand_mitsubishi_click',
  WEB_USED_CAR_PLP_FILTER_BRAND_SUZUKI_CLICK = 'web_used_car_plp_filter_brand_suzuki_click',
  WEB_USED_CAR_PLP_FILTER_BRAND_HYUNDAI_CLICK = 'web_used_car_plp_filter_brand_hyundai_click',
  WEB_USED_CAR_PLP_FILTER_BRAND_ISUZU_CLICK = 'web_used_car_plp_filter_brand_isuzu_click',
  WEB_USED_CAR_PLP_FILTER_BRAND_BMW_CLICK = 'web_used_car_plp_filter_brand_bmw_click',
  WEB_USED_CAR_PLP_FILTER_BRAND_PEUGEOT_CLICK = 'web_used_car_plp_filter_brand_peugeot_click',
  WEB_USED_CAR_PLP_FILTER_BRAND_LAINNYA_CLICK = 'web_used_car_plp_filter_brand_lainnya_click',
  WEB_USED_CAR_PLP_FILTER_YEAR_INPUT = 'web_used_car_plp_filter_year_input',
  WEB_USED_CAR_PLP_FILTER_TRANSMISSION_MANUAL_CLICK = 'web_used_car_plp_filter_transmission_manual_click',
  WEB_USED_CAR_PLP_FILTER_TRANSMISSION_OTOMATIS_CLICK = 'web_used_car_plp_filter_transmission_otomatis_click',
  WEB_USED_CAR_PLP_FILTER_PLATE_GANJIL_CLICK = 'web_used_car_plp_filter_plate_ganjil_click',
  WEB_USED_CAR_PLP_FILTER_PLATE_GENAP_CLICK = 'web_used_car_plp_filter_plate_genap_click',
  WEB_USED_CAR_PLP_FILTER_LOCATION_CLICK = 'web_used_car_plp_filter_location_click',
  WEB_USED_CAR_PLP_FILTER_MILLEAGE_INPUT = 'web_used_car_plp_filter_milleage_input',
  WEB_USED_CAR_PLP_FILTER_PRICE_INPUT = 'web_used_car_plp_filter_price_input',
  WEB_USED_CAR_PLP_SORT_LOWEST_PRICE_CLICK = 'web_used_car_plp_sort_lowest_price_click',
  WEB_USED_CAR_PLP_SORT_HIGHEST_PRICE_CLICK = 'web_used_car_plp_sort_highest_price_click',
  WEB_USED_CAR_PLP_SORT_NEWEST_CLICK = 'web_used_car_plp_sort_newest_click',
  WEB_USED_CAR_PLP_SORT_OLDEST_CLICK = 'web_used_car_plp_sort_oldest_click',
  WEB_USED_CAR_PLP_PRODUCT_CLICK = 'web_used_car_plp_product_click',
  WEB_USED_CAR_PLP_LIHAT_DETAIL_CLICK = 'web_used_car_plp_lihat_detail_click',
  WEB_USED_CAR_PLP_TANYA_UNIT_CLICK = 'web_used_car_plp_tanya_unit_click',
  WEB_USED_CAR_PLP_LEADS_FORM_SUCCESS = 'web_used_car_plp_leads_form_success',

  //PDP Used Car
  WEB_USED_CAR_PDP_GALLERY_CLICK = 'web_used_car_pdp_gallery_click',
  WEB_USED_CAR_PDP_DESCRIPTION_TAB_CLICK = 'web_used_car_pdp_description_tab_click',
  WEB_USED_CAR_PDP_CREDIT_TAB_CLICK = 'web_used_car_pdp_credit_tab_click',
  WEB_USED_CAR_PDP_HITUNG_CICILAN_CLICK = 'web_used_car_pdp_hitung_cicilan_click',
  WEB_USED_CAR_PDP_TANYA_UNIT_CLICK = 'web_used_car_pdp_tanya_unit_click',
  WEB_USED_CAR_PDP_LEADS_FORM_SUCCESS = 'web_used_car_pdp_leads_form_success',
  WEB_USED_CAR_PDP_RECO_NEW_CLICK = 'web_used_car_pdp_reco_new_click',
  WEB_USED_CAR_PDP_RECO_NEW_LIHAT_DETAIL_CLICK = 'web_used_car_pdp_reco_new_lihat_detail_click',
  WEB_USED_CAR_PDP_RECO_USED_CLICK = 'web_used_car_pdp_reco_used_click',
  WEB_USED_CAR_PDP_RECO_USED_LIHAT_DETAIL_CLICK = 'web_used_car_pdp_reco_used_lihat_detail_click',
  WEB_USED_CAR_PDP_RECO_USED_TANYA_UNIT_CLICK = 'web_used_car_pdp_reco_used_tanya_unit_click',
  WEB_USED_CAR_PDP_RECO_USED_LEADS_FORM_SUCCESS = 'web_used_car_pdp_reco_used_leads_form_success',

  //Loan Calculator Used Car
  WEB_USED_CAR_LC_DP_AMOUNT_INPUT = 'web_used_car_lc_dp_amount_input',
  WEB_USED_CAR_LC_DP_PERCENTAGE_INPUT = 'web_used_car_lc_dp_percentage_input',
  WEB_USED_CAR_LC_HITUNG_CICILAN_CLICK = 'web_used_car_lc_hitung_cicilan_click',
  WEB_USED_CAR_LC_TENURE_1Y_CLICK = 'web_used_car_lc_tenure_1y_click',
  WEB_USED_CAR_LC_TENURE_2Y_CLICK = 'web_used_car_lc_tenure_2y_click',
  WEB_USED_CAR_LC_TENURE_3Y_CLICK = 'web_used_car_lc_tenure_3y_click',
  WEB_USED_CAR_LC_TENURE_4Y_CLICK = 'web_used_car_lc_tenure_4y_click',
  WEB_USED_CAR_LC_TENURE_5Y_CLICK = 'web_used_car_lc_tenure_5y_click',
  WEB_USED_CAR_LC_CEK_ASURANSI_CLICK = 'web_used_car_lc_cek_asuransi_click',
  WEB_USED_CAR_LC_INSURANCE_OPTION_CLICK = 'web_used_car_lc_insurance_option_click',
  WEB_USED_CAR_LC_TERAPKAN_ASURANSI_CLICK = 'web_used_car_lc_terapkan_asuransi_click',
  WEB_USED_CAR_LC_TANYA_UNIT_CLICK = 'web_used_car_lc_tanya_unit_click',
  WEB_USED_CAR_LC_LEADS_FORM_SUCCESS = 'web_used_car_lc_leads_form_success',

  //Homepage Leads
  WEB_HOMEPAGE_CAR_OF_THE_MONTH_LEADS_FORM_SUCCESS = 'web_homepage_car_of_the_month_leads_form_success',
  WEB_HOMEPAGE_LEADS_FORM_SUCCESS = 'web_homepage_leads_form_success',

  //New Car Leads
  WEB_NEW_CAR_PLP_LEADS_FORM_SUCCESS = 'web_new_car_plp_leads_form_success',
  WEB_NEW_CAR_PDP_LEADS_FORM_SUCCESS = 'web_new_car_pdp_leads_form_success',
  WEB_NEW_CAR_KUALIFIKASI_KREDIT_SUCCESS = 'web_new_car_kualifikasi_kredit_success',
  WEB_NEW_CAR_INSTANT_APPROVAL_SUCCESS = 'web_new_car_instant_approval_success',

  //Klaim Promo
  WEB_KLAIM_PROMO_SUCCESS_BUTTON_CLICK = 'web_klaim_promo_success_button_click',
  WEB_KLAIM_PROMO_PAGE_CLAIM_CLICK = 'web_klaim_promo_page_claim_click',
  WEB_KLAIM_PROMO_FORM_UPLOAD_BSTK_CLICK = 'web_klaim_promo_form_upload_bstk_click',
  WEB_KLAIM_PROMO_FORM_UPLOAD_SERAH_TERIMA_CLICK = 'web_klaim_promo_form_upload_serah_terima_click',
  WEB_KLAIM_PROMO_FORM_UPLOAD_ASTRAPAY_CLICK = 'web_klaim_promo_form_upload_astrapay_click',
  WEB_KLAIM_PROMO_FORM_UPLOAD_BANKSAQU_CLICK = 'web_klaim_promo_form_uplaod_banksaqu_click',
  WEB_KLAIM_PROMO_FORM_SUBMIT_CLICK = 'web_klaim_promo_form_submit_click',

  //Refinancing Leads
  WEB_REFINANCING_1ST_FORM_LEADS_FORM_SUCCESS = 'web_refinancing_1st_form_leads_form_success',
  WEB_REFINANCING_2ND_FORM_LEADS_FORM_SUCCESS = 'web_refinancing_2nd_form_leads_form_succes',
}
