import React, { useEffect, useRef } from 'react'
import styles from 'styles/components/molecules/navigationTabV4.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { Swiper as SwiperType } from 'swiper'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import { TabItemData } from 'utils/types/props'
import { IconChevronLeft, IconChevronRight } from 'components/atoms'
import clsx from 'clsx'

interface Props {
  id: string
  itemList: TabItemData[]
  selectedTab: string
  onSelectTab: (value: string) => void
  additionalClassContainer?: string
  additionalClassSwiper?: string
}

export const NavigationTabV4 = ({
  id,
  itemList,
  selectedTab,
  onSelectTab,
  additionalClassContainer = '',
  additionalClassSwiper = '',
}: Props) => {
  const swiperRef = useRef<SwiperType>()

  const scroll = (index: number) => {
    if (swiperRef && swiperRef.current) {
      swiperRef.current.slideTo(index)
    }
  }

  const checkActiveTab = () => {
    const itemIndex = itemList.findIndex((x) => x.value === selectedTab)
    scroll(itemIndex)
  }

  useEffect(() => {
    checkActiveTab()
  }, [selectedTab])

  return (
    <div
      className={clsx({
        [styles.container]: true,
        [additionalClassContainer]: !!additionalClassContainer,
      })}
    >
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={22}
        pagination={false}
        className={clsx({
          [styles.swiperStyle]: true,
          [additionalClassSwiper]: !!additionalClassSwiper,
        })}
        onBeforeInit={(ref: SwiperType) => (swiperRef.current = ref)}
        navigation={{
          nextEl: '.swiper-button-next-car-spec-modal' + id,
          prevEl: '.swiper-button-prev-car-spec-modal' + id,
        }}
        modules={[Navigation]}
        cssMode
      >
        {itemList.map((item, index) => {
          return (
            <SwiperSlide
              key={index}
              onClick={() => onSelectTab(item.value)}
              style={{ width: 'fit-content' }}
              className={styles.swiperSlideStyle}
            >
              <div className={styles.tabItem}>
                <span
                  className={clsx({
                    [styles.tabItemText]: true,
                    [styles.tabItemTextSelected]: selectedTab === item.value,
                  })}
                >
                  {item.label}
                </span>
                <div
                  className={clsx({
                    [styles.bottomBlueLine]: selectedTab === item.value,
                  })}
                ></div>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <button
        className={`${styles.chevronButton} ${styles.previousButton} ${
          'swiper-button-prev-car-spec-modal' + id
        }`}
      >
        <IconChevronLeft width={24} height={24} color="#05256E" />
      </button>
      <button
        className={`${styles.chevronButton} ${styles.nextButton} ${
          'swiper-button-next-car-spec-modal' + id
        }`}
      >
        <IconChevronRight width={24} height={24} color="#05256E" />
      </button>
    </div>
  )
}
