import { initializeApp } from 'firebase/app'
import { Environment } from 'utils/enum'
import getCurrentEnvironment from 'utils/handler/getCurrentEnvironment'

const firebaseConfigLocalhost = {
  apiKey: 'AIzaSyBi0MBSiB88xCvbti1T8plNTreX-bzZfAw',
  authDomain: 'torq-308606.firebaseapp.com',
  projectId: 'torq-308606',
  storageBucket: 'torq-308606.appspot.com',
  messagingSenderId: '349467238591',
  appId: '1:349467238591:web:757b788dc92fbb83e5a60a',
  measurementId: 'G-8CV89SPCYW',
}

const firebaseConfigDevelopment = {
  apiKey: 'AIzaSyBi0MBSiB88xCvbti1T8plNTreX-bzZfAw',
  authDomain: 'torq-308606.firebaseapp.com',
  projectId: 'torq-308606',
  storageBucket: 'torq-308606.appspot.com',
  messagingSenderId: '349467238591',
  appId: '1:349467238591:web:757b788dc92fbb83e5a60a',
  measurementId: 'G-8CV89SPCYW',
}

const firebaseConfigStaging = {
  apiKey: 'AIzaSyD2bJ778FiGTjmZAQODncPX6t5vO_04OJU',
  authDomain: 'torq-staging-310201.firebaseapp.com',
  projectId: 'torq-staging-310201',
  storageBucket: 'torq-staging-310201.appspot.com',
  messagingSenderId: '828696580488',
  appId: '1:828696580488:web:b587e5f2bc0dc81529aa3d',
}

const firebaseConfigProduction = {
  apiKey: 'AIzaSyCZvAEoXz7Y9adQbDfTg4uz2s2-B1Zv6F8',
  authDomain: 'torq-prod.firebaseapp.com',
  projectId: 'torq-prod',
  storageBucket: 'torq-prod.appspot.com',
  messagingSenderId: '978280076410',
  appId: '1:978280076410:web:a15e9c98f7f28d5492d7c3',
  measurementId: 'G-B783G4KR5J',
}

const getFirebaseConfig = (env: Environment) => {
  switch (env) {
    case Environment.Localhost:
      return firebaseConfigLocalhost
    case Environment.Development:
      return firebaseConfigDevelopment
    case Environment.Staging:
      return firebaseConfigStaging
    case Environment.Production:
      return firebaseConfigProduction
    default:
      return firebaseConfigLocalhost
  }
}

const envName: Environment =
  (process.env.NEXT_PUBLIC_ENVIRONMENT as Environment) || 'localhost'

const firebaseConfig = getFirebaseConfig(envName)
export const app = initializeApp(firebaseConfig)
