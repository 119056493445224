import { on } from 'events'
import elementId from 'utils/helpers/trackerId'
import { capitalizeFirstLetter, capitalizeWords } from 'utils/handler/string'

export const upperSectionNavigationTab = [
  {
    label: 'Warna',
    value: 'Warna',
    testid: elementId.Tab + 'warna',
  },
  {
    label: 'Eksterior',
    value: 'Eksterior',
    testid: elementId.Tab + 'eksterior',
  },
  {
    label: 'Interior',
    value: 'Interior',
    testid: elementId.Tab + 'interior',
  },
  {
    label: 'Video',
    value: 'Video',
    testid: elementId.Tab + 'video',
  },
  {
    label: '360º Eksterior',
    value: '360º Eksterior',
    testid: elementId.Tab + '360-exterior',
  },
  {
    label: '360º Interior',
    value: '360º Interior',
    testid: elementId.Tab + '360-interior',
  },
]

export const getSeoFooterTextDescription = (
  brand?: string,
  model?: string,
  bodyType?: string,
) => {
  const currentYear: number = new Date().getFullYear()
  return `<p>Bagi anda yang ingin memiliki mobil baru dengan harga dan cicilan terjangkau? ${model} adalah pilihan yang tepat. SEVA, platform resmi dari Astra, memberikan kemudahan dalam proses pembelian mobil ${brand} ${model}. Dengan penawaran harga kompetitif, promo dan diskon yang menarik, serta fasilitas kredit yang mudah.
  <br /><br />
  ${brand} ${model} telah lama menjadi pilihan populer di segmen ${bodyType} di Indonesia, dari segi eksterior ${model} menampilkan tampilan modern yang menarik, Gril depan yang menonjol memberikan kesan tangguh dan maskulin. Lampu depan yang stylish dilengkapi dengan LED yang memberikan pencahayaan optimal.
  <br /><br />
  Interior ${model} dirancang dengan fokus pada ruang dan fungsionalitas. Material berkualitas tinggi yang digunakan dalam pembuatan interior menjadikan ${model} nyaman dan tahan lama. Sistem suspensi yang disesuaikan dengan baik mengurangi getaran jalan dan memberikan perjalanan yang halus, bahkan di medan yang tidak rata. 
  <br /><br />
  Harga mobil baru ${brand} ${model} dijamin sangat kompetitif, terutama dengan adanya diskon khusus dan penawaran kredit yang menguntungkan konsumen. Astra, melalui SEVA, menyediakan kemudahan dengan proses pembelian yang cepat dan transparan, serta memudahkan konsumen untuk mendapatkan informasi terkini seputar harga dan promo yang sedang berlangsung.
  <br /><br />
  SEVA juga menawarkan keistimewaan dengan program Instant Approval untuk kredit pembelian mobil baru. Konsumen dapat dengan cepat mengetahui persetujuan kredit mereka, memungkinkan untuk segera memiliki ${brand} ${model} impian mereka tanpa menunggu waktu yang lama.
  <br /><br />
  Bagi para konsumen atau pecinta mobil ${model} yang tengah mencari mobil baru, tidak ada salahnya untuk cek penawaran menarik yang ditawarkan oleh Astra melalui SEVA. Dapatkan keistimewaan promo diskon, penawaran kredit yang menguntungkan, serta proses pembelian yang cepat dan transparan untuk memiliki ${brand} ${model} impian Anda.
  </p>`
}

export const getSeoFooterTentangGiiasDescription = () => {
  const currentYear: number = new Date().getFullYear()
  return `<p>GIIAS atau Gaikindo Indonesia International Auto Show, merupakan pameran otomotif terbesar di Indonesia dan setiap tahun, GIIAS menjadi sorotan bagi para pecinta dan pelaku industri otomotif. SEVA, sebagai platform jual beli mobil online terpercaya, selalu hadir di GIIAS setiap tahunnya untuk menemani perjalanan kamu dalam menemukan kendaraan impian dengan mudah, aman dan nyaman. Sejak tahun 2022, SEVA telah membantu banyak pengunjung GIIAS mendapatkan mobil baru dengan mudah, menyenangkan, dan menguntungkan melalui platform online di www.seva.id 
  <br /><br />
  Di GIIAS 2024, SEVA hadir dengan hadir dengan beragam promo menarik dan layanan terbaik untuk Sobat SEVA termasuk simulasi kredit untuk pembelian mobil baru maupun mobil bekas. SEVA menantang kamu sobat SEVA untuk berani beli mobil online dari beragam pilihan mobil baru mulai dari Toyota Ayla, Daihatsu All New Xenia, hingga Hyundai Stargazer dan berbagai merk mobil lainnya. Pada pameran tersebut, SEVA juga turut menghadirkan fitur Instant Approval, di mana pengunjung GIIAS yang ingin mengajukan kredit mobil baru bisa dengan cepat mengetahui persetujuan pengajuan kredit mobil baru kamu! Kunjungi SEVA di GIIAS 2024 dan wujudkan beli mobil baru impian kamu!</p>`
}

export const getSeoFooterGiiasDescription = () => {
  const currentYear: number = new Date().getFullYear()
  return `<p>Saat ini membeli mobil bekas bukanlah hal buruk. Di tahun 2023, data menunjukan bahwa pembelian mobil baru mengalami peningkatan yang cukup signifikan, ini artinya mobil baru masih menjadi pilihan banyak orang. Jika kamu berniat membeli mobil baru, mobil baru Toyota Raize dapat menjadi pilihan yang tepat.​Membeli mobil baru sama halnya seperti membeli mobil bekas, kita juga harus memperhatikan perawatannya, karena mobil yang rajin perawatan tentu akan bertahan untuk jangka waktu yang panjang. Perawatan yang bisa dilakukan untuk mobil baru Toyota Raize adalah pergantian oli, filter AC, periksa tekanan ban, serta mencuci mobil. 
  </p>`
}

export const getSeoFooterDealerTextDescription = (
  onPage?: string,
  brand?: string,
  branchCount?: string,
  location?: string,
  branchName?: string,
) => {
  const currentYear: number = new Date().getFullYear()
  if (onPage === 'main') {
    return `<p>Dealer atau showroom mobil adalah dealer yang bekerjasama dengan SEVA Astra dan dikenal akan kualitasnya dan dikenal dengan berbagai layanan, promo, dan keunggulan pelayanan. Dealer atau showroom mobil rekanan SEVA bisa jadi pilihan utama bagi pecinta mobil di Indonesia.
    <br/><br/>
    Dealer-dealer rekananan SEVA hadir dengan berbagai pilihan varian mobil berkualitas untuk anda. Pengunjung juga dapat menikmati pengalaman melihat secara langsung dan test drive beragam pilihan mobil yang ditawarkan dengan berbagai fitur dan warna yang menarik.
    <br/><br/>
    Setiap pembelian mobil di dealer atau showroom rekanan SEVA, anda akan mendapatkan penawaran promo mobil menarik dan diskon eksklusif lainnya. Program-program ini memberikan benefit tambahan bagi konsumen yang ingin membeli mobil dengan penawaran yang menguntungkan.
    <br/><br/>
    Dealer mobil kami juga memberikan kemudahan dalam hal pembayaran dengan berbagai opsi kredit dan cicilan. Hal ini membuat pembelian mobil semakin terjangkau bagi konsumen, dengan paket kredit yang dapat disesuaikan dengan kebutuhan masing-masing.
    Pelayanan purna jual di dealer atau showroom mobil SEVA tidak hanya terbatas pada penjualan mobil. Kami memiliki jaringan service center dan bengkel yang tersebar di berbagai lokasi di Indonesia. Dengan tim teknisi yang terlatih dan menggunakan peralatan canggih, layanan perawatan dan perbaikan mobil dijamin berkualitas tinggi.
    <br/><br/>
    Dealer atau showroom mobil kami menjamin ketersediaan sparepart dan suku cadang asli. Hal ini memberikan kepastian kepada pelanggan bahwa perawatan mobil anda akan menggunakan komponen yang memiliki kualitas dan keandalan sesuai standar pabrik.
    <br><br>
    Dealer partner kami tidak hanya menjadi tempat pembelian mobil, tetapi juga mitra dalam menjaga kualitas mobilitas. Dari penawaran yang menggiurkan hingga layanan purna jual yang prima, dealer mobil rekanan SEVA telah terbukti sebagai dealer mobil yang dapat diandalkan dan berkualitas di tengah hiruk-pikuknya kebutuhan akan mobilitas di Indonesia.
  </p>`
  } else if (onPage === 'brand') {
    return `<p>Temukan beragam Dealer ${
      brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
    } yang bekerjasama dengan SEVA. Terdapat ${branchCount} Dealer ${
      brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
    } yang dikenal akan kualitas, promo serta keunggulan pelayanannya. Dealer ${
      brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
    } bisa jadi pilihan utama bagi pecinta mobil ${
      brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
    } di Indonesia.
  <br /><br />
  Dealer ${
    brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
  } hadir dengan berbagai pilihan varian mobil berkualitas untuk anda. Pengunjung juga dapat menikmati pengalaman melihat secara langsung dan test drive beragam pilihan mobil yang ditawarkan dengan berbagai fitur dan warna yang menarik.
  <br /><br />
  Setiap pembelian mobil ${
    brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
  }, anda akan diberikan promo menarik dan diskon eksklusif lainnya. Program-program ini memberikan benefit tambahan bagi konsumen yang ingin membeli mobil dengan penawaran yang menguntungkan.
  <br /><br />
  Kami juga memberikan kemudahan dalam hal pembayaran dengan berbagai opsi kredit dan cicilan. Hal ini membuat pembelian mobil semakin terjangkau bagi konsumen, dengan paket kredit yang dapat disesuaikan dengan kebutuhan masing-masing.
  <br /><br />
  Pelayanan purna jual di ${
    brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
  } tidak hanya terbatas pada penjualan mobil. ${
      brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
    } memiliki jaringan service center dan bengkel yang tersebar di berbagai lokasi di Indonesia. Dengan tim teknisi yang terlatih dan menggunakan peralatan canggih, layanan perawatan dan perbaikan mobil dijamin berkualitas tinggi.
  <br /><br />
  ${
    brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
  } menjamin ketersediaan sparepart dan suku cadang asli ${
      brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
    }. Hal ini memberikan kepastian kepada pelanggan bahwa perawatan mobil anda akan menggunakan komponen yang memiliki kualitas dan keandalan sesuai standar pabrik ${
      brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
    }.
  <br /><br />
  Dealer ${
    brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
  } tidak hanya menjadi tempat pembelian mobil, tetapi juga mitra dalam menjaga kualitas mobilitas. Dari penawaran yang menggiurkan hingga layanan purna jual yang prima, Dealer ${
      brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
    } telah membuktikan dirinya sebagai dealer mobil baru yang dapat diandalkan dan berkualitas di tengah hiruk-pikuknya kebutuhan akan mobilitas di Indonesia.
  </p>`
  } else if (onPage === 'location') {
    return `<p>Temukan beragam Dealer ${
      brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
    } di ${capitalizeWords(location!)}. Dealer ${
      brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
    } di ${capitalizeWords(
      location!,
    )} dikenal akan kualitasnya dan dikenal dengan layanan hingga promo. Dealer ${
      brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
    } di ${capitalizeWords(
      location!,
    )} bisa jadi dealer atau showroom pilihan utama bagi pecinta mobil ${
      brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
    } di ${capitalizeWords(location!)}.
  <br /><br />
  Dealer ${
    brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
  } di ${capitalizeWords(
      location!,
    )} hadir dengan berbagai pilihan varian mobil berkualitas untuk anda. Pengunjung juga dapat menikmati pengalaman melihat secara langsung dan test drive beragam pilihan mobil yang ditawarkan dengan berbagai fitur dan warna yang menarik.
  <br /><br />
  Setiap pembelian mobil ${
    brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
  } di ${capitalizeWords(
      location!,
    )}, anda akan diberikan promo menarik dan diskon eksklusif lainnya. Program-program ini memberikan nilai lebih dan benefit tambahan bagi konsumen yang ingin membeli mobil dengan penawaran yang menguntungkan.
  <br /><br />
  Dealer ${
    brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
  } juga memberikan kemudahan dalam hal pembayaran dengan berbagai opsi kredit dan cicilan. Hal ini membuat pembelian mobil semakin terjangkau bagi konsumen, dengan paket kredit yang dapat disesuaikan dengan kebutuhan masing-masing.
  <br /><br />
  Pelayanan purna jual di seluruh dealer ${
    brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
  } ${capitalizeWords(location!)} tidak hanya terbatas pada penjualan mobil ${
      brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
    }. Kami memiliki jaringan service center dan bengkel yang tersebar di berbagai lokasi di ${capitalizeWords(
      location!,
    )}. Dengan tim teknisi yang terlatih dan menggunakan peralatan canggih, layanan perawatan dan perbaikan mobil dijamin berkualitas tinggi.
  <br /><br />
  Dealer ${
    brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
  } menjamin ketersediaan sparepart dan suku cadang asli ${
      brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
    }. Hal ini memberikan kepastian kepada pelanggan bahwa perawatan mobil anda akan menggunakan komponen yang memiliki kualitas dan keandalan sesuai standar pabrik ${
      brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
    }.
  <br /><br />
  Dealer ${
    brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
  } di ${capitalizeWords(
      location!,
    )} tidak hanya menjadi tempat pembelian mobil, tetapi juga mitra dalam menjaga kualitas mobilitas. Dari penawaran yang menggiurkan hingga layanan purna jual yang prima, Dealer terbukti sebagai dealer mobil ${
      brand! !== 'bmw' ? capitalizeFirstLetter(brand!) : brand!.toUpperCase()
    } yang dapat diandalkan dan berkualitas di tengah hiruk-pikuknya kebutuhan akan mobilitas di Indonesia.
  <br /><br />
  </p>`
  } else {
    return `<p>${branchName} sebagai dealer yang bekerjasama dengan SEVA dan dikenal akan kualitasnya dan dikenal dengan berbagai layanan, promo, dan keunggulan pelayanan, ${branchName} menjelma sebagai dealer atau showroom pilihan utama bagi pecinta mobil di Indonesia.
  <br /><br />
  ${branchName} hadir dengan berbagai pilihan varian mobil berkualitas untuk anda. Pengunjung juga dapat menikmati pengalaman melihat secara langsung dan test drive beragam pilihan mobil yang ditawarkan dengan berbagai fitur dan warna yang menarik.
  <br /><br />
  Setiap pembelian mobil di ${branchName}, anda akan diberikan promo mobil menarik dan diskon eksklusif lainnya. Program-program ini memberikan nilai lebih dan benefit tambahan bagi konsumen yang ingin membeli mobil dengan penawaran yang menguntungkan.
  <br /><br />
  ${branchName} juga memberikan kemudahan dalam hal pembayaran dengan berbagai opsi kredit dan cicilan. Hal ini membuat pembelian mobil semakin terjangkau bagi konsumen, dengan paket kredit yang dapat disesuaikan dengan kebutuhan masing-masing.
  <br /><br />
  Pelayanan purna jual di ${branchName} tidak hanya terbatas pada penjualan mobil. Kami memiliki jaringan service center dan bengkel yang tersebar di berbagai lokasi di Indonesia. Dengan tim teknisi yang terlatih dan menggunakan peralatan canggih, layanan perawatan dan perbaikan mobil dijamin berkualitas tinggi.
  <br /><br />
  ${branchName} menjamin ketersediaan sparepart dan suku cadang asli. Hal ini memberikan kepastian kepada pelanggan bahwa perawatan mobil anda akan menggunakan komponen yang memiliki kualitas dan keandalan sesuai standar pabrik.
  <br /><br />
  ${branchName} tidak hanya menjadi tempat pembelian mobil, tetapi juga mitra dalam menjaga kualitas mobilitas. Dari penawaran yang menggiurkan hingga layanan purna jual yang prima, ${branchName} telah membuktikan dirinya sebagai dealer mobil yang dapat diandalkan dan berkualitas di tengah hiruk-pikuknya kebutuhan akan mobilitas di Indonesia.
  <br /><br />
  </p>`
  }
}
