import { useCallback, useState } from 'react'

export const useOnceClick = () => {
  const [clicked, setClicked] = useState(false)

  const onClickOnce = useCallback(
    (func: () => void) => {
      if (clicked) return
      func()
      setClicked(true)
    },
    [clicked],
  )

  return { onClickOnce }
}
