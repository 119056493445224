import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconTransmission: React.FC<PropsIcon> = ({
  width,
  height,
  alt,
  color = '#05256E',
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{alt}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.94475 8.25C7.22872 8.25 6.71167 8.80425 6.71167 9.41089C6.71167 10.0175 7.22872 10.5718 7.94475 10.5718C8.66077 10.5718 9.17782 10.0175 9.17782 9.41089C9.17782 8.80425 8.66077 8.25 7.94475 8.25ZM4.21167 9.41089C4.21167 7.35453 5.91804 5.75 7.94475 5.75C9.97145 5.75 11.6778 7.35453 11.6778 9.41089C11.6778 11.4673 9.97145 13.0718 7.94475 13.0718C5.91804 13.0718 4.21167 11.4673 4.21167 9.41089Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8977 8.25C19.1817 8.25 18.6647 8.80425 18.6647 9.41089C18.6647 10.0175 19.1817 10.5718 19.8977 10.5718C20.6138 10.5718 21.1308 10.0175 21.1308 9.41089C21.1308 8.80425 20.6138 8.25 19.8977 8.25ZM16.1647 9.41089C16.1647 7.35453 17.871 5.75 19.8977 5.75C21.9244 5.75 23.6308 7.35453 23.6308 9.41089C23.6308 11.4673 21.9244 13.0718 19.8977 13.0718C17.871 13.0718 16.1647 11.4673 16.1647 9.41089Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.94475 29.4282C7.22872 29.4282 6.71167 29.9824 6.71167 30.5891C6.71167 31.1957 7.22872 31.75 7.94475 31.75C8.66077 31.75 9.17782 31.1957 9.17782 30.5891C9.17782 29.9824 8.66077 29.4282 7.94475 29.4282ZM4.21167 30.5891C4.21167 28.5327 5.91804 26.9282 7.94475 26.9282C9.97145 26.9282 11.6778 28.5327 11.6778 30.5891C11.6778 32.6454 9.97145 34.25 7.94475 34.25C5.91804 34.25 4.21167 32.6454 4.21167 30.5891Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8977 29.4282C19.1817 29.4282 18.6647 29.9824 18.6647 30.5891C18.6647 31.1957 19.1817 31.75 19.8977 31.75C20.6138 31.75 21.1308 31.1957 21.1308 30.5891C21.1308 29.9824 20.6138 29.4282 19.8977 29.4282ZM16.1647 30.5891C16.1647 28.5327 17.871 26.9282 19.8977 26.9282C21.9244 26.9282 23.6308 28.5327 23.6308 30.5891C23.6308 32.6454 21.9244 34.25 19.8977 34.25C17.871 34.25 16.1647 32.6454 16.1647 30.5891Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.8657 8.25C31.1497 8.25 30.6326 8.80425 30.6326 9.41089C30.6326 10.0175 31.1497 10.5718 31.8657 10.5718C32.5817 10.5718 33.0988 10.0175 33.0988 9.41089C33.0988 8.80425 32.5817 8.25 31.8657 8.25ZM28.1326 9.41089C28.1326 7.35453 29.839 5.75 31.8657 5.75C33.8924 5.75 35.5988 7.35453 35.5988 9.41089C35.5988 11.4673 33.8924 13.0718 31.8657 13.0718C29.839 13.0718 28.1326 11.4673 28.1326 9.41089Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.94433 10.5574C8.63469 10.5574 9.19433 11.1171 9.19433 11.8074V28.1784C9.19433 28.8688 8.6351 29.4282 7.94475 29.4282C7.25439 29.4282 6.69433 28.8688 6.69433 28.1784V11.8074C6.69433 11.1171 7.25397 10.5574 7.94433 10.5574Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8982 10.5574C20.5885 10.5574 21.1482 11.1171 21.1482 11.8074V28.1784C21.1482 28.8688 20.5881 29.4282 19.8977 29.4282C19.2074 29.4282 18.6482 28.8688 18.6482 28.1784V11.8074C18.6482 11.1171 19.2078 10.5574 19.8982 10.5574Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.8656 10.5574C32.5559 10.5574 33.1156 11.1171 33.1156 11.8074V19.9929C33.1156 20.6833 32.5559 21.2429 31.8656 21.2429H7.94433C7.25397 21.2429 6.69433 20.6833 6.69433 19.9929C6.69433 19.3026 7.25397 18.7429 7.94433 18.7429H30.6156V11.8074C30.6156 11.1171 31.1752 10.5574 31.8656 10.5574Z"
        fill={color}
      />
    </svg>
  )
}
