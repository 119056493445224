import { useEffect, useState } from 'react'
import {
  fetchAndActivate,
  getRemoteConfig,
  getBoolean,
} from 'firebase/remote-config'
import { app } from 'services/firebase/config'
import { RemoteConfigKey } from 'utils/enum'

const initializeRemoteConfig = () => {
  const remoteConfig = getRemoteConfig(app)
  remoteConfig.settings.minimumFetchIntervalMillis = 3600000 // 1 hour
  return remoteConfig
}

const useRemoteConfigValue = (key: RemoteConfigKey, defaultValue: boolean) => {
  const [value, setValue] = useState<boolean>(defaultValue)

  useEffect(() => {
    const fetchConfigValue = async () => {
      if (typeof window !== 'undefined') {
        const remoteConfig = initializeRemoteConfig()
        await fetchAndActivate(remoteConfig)
        const configValue = getBoolean(remoteConfig, key)
        setValue(configValue)
      }
    }

    fetchConfigValue()
  }, [key])

  return value
}

export default useRemoteConfigValue
