import React from 'react'
import styles from 'styles/components/atoms/tabV2.module.scss'
import { TabItemProps } from 'utils/types/props'

export const TabV2 = ({
  label,
  value,
  isActive,
  onClickHandler,
  dataTestId,
  onPage,
}: TabItemProps) => {
  switch (onPage) {
    case 'search-widget':
      return (
        <div
          className={`${styles.container} ${
            isActive && styles.containerActive
          }`}
          role="button"
          onClick={() => onClickHandler && onClickHandler(value)}
          data-testid={dataTestId}
        >
          <h3 className={`${styles.text} ${isActive && styles.textActive}`}>
            {label}
          </h3>
        </div>
      )
    case 'Giias':
      return (
        <div
          className={`${styles.containerTabV2LC} ${
            isActive && onPage === 'Giias'
              ? styles.containerActiveGiias
              : isActive
              ? styles.containerActive
              : ''
          }`}
          role="button"
          onClick={() => onClickHandler && onClickHandler(value)}
          data-testid={dataTestId}
        >
          <h3
            className={`${
              onPage === 'Giias' ? styles.textGiias : styles.text
            } ${
              isActive && onPage === 'Giias'
                ? styles.textActiveGiias
                : styles.textActive
            }`}
          >
            {label}
          </h3>
        </div>
      )
    default:
      return (
        <div
          className={`${styles.containerPdp} ${
            isActive && styles.containerActive
          }`}
          role="button"
          onClick={() => onClickHandler && onClickHandler(value)}
          data-testid={dataTestId}
        >
          <h3 className={`${styles.text} ${isActive && styles.textActive}`}>
            {label}
          </h3>
        </div>
      )
  }
}
