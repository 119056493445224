import React, { InputHTMLAttributes } from 'react'
import styles from 'styles/components/atoms/input.module.scss'
import clsx from 'clsx'
import { PropsInput } from 'utils/types/props'
import { InputVersionType } from 'utils/enum'

const Input = ({
  value,
  placeholder,
  onChange,
  isError,
  message,
  disabled,
  title,
  dataTestId,
  className,
  version,
  additionalWrapperClassname,
  ...props
}: PropsInput): JSX.Element => {
  return (
    <div className={`${styles.wrapper} ${additionalWrapperClassname}`}>
      {title && (
        <p
          className={
            version === InputVersionType.Secondary
              ? styles.titleSecondaryText
              : version === InputVersionType.Tertiary
              ? styles.titleTertiaryText
              : version === InputVersionType.Dark
              ? styles.titleDarkText
              : styles.titlePrimaryText
          }
        >
          {title}
        </p>
      )}
      <input
        data-test-id={dataTestId}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={clsx({
          [styles.input]: true,
          [styles.inputTertiary]: version === InputVersionType.Tertiary,
          [styles.error]: isError,
          [styles.disabled]: disabled,
          ['shake-animation-X']: isError,
          ...(className && [className]),
        })}
        placeholder={placeholder}
        {...props}
      />
      {isError && message && <p className={styles.errorText}>{message}</p>}
    </div>
  )
}

export default Input
