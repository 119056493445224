// import { VideoReviewType } from 'types/types'

export const availableListColors = [
  // === daihatsu cars ====
  {
    colors: [
      '#000000',
      '#ffffff',
      '#b5a5cc',
      '#374542',
      '#474747',
      '#acb8c0',
      '#d92121',
    ],
    url: '/daihatsu/all-new-xenia', //xenia
  },
  {
    colors: ['#000000', '#808080', '#c0c0c0', '#ffffff', '#800040', '#800000'],
    url: '/daihatsu/all-new-terios', //terios
  },
  {
    colors: ['#000000', '#ffffff', '#c0c0c0', '#525252'],
    url: '/daihatsu/granmax-mb', //grandmax mb
  },
  {
    colors: [
      '#000000',
      '#ffffff',
      '#474747',
      '#acb8c0',
      '#ffff00',
      '#da680f',
      '#e0115f',
      '#d92121',
    ],
    url: '/daihatsu/new-ayla', //ayla
  },
  {
    colors: [
      '#000000',
      '#808080',
      '#ffffff',
      '#c0c0c0',
      '#5c524d',
      '#800000',
      '#ff8000',
    ],
    url: '/daihatsu/sigra', //sigra
  },
  {
    colors: ['#000000', '#ffffff', '#c4c4c4', '#474747'],
    url: '/daihatsu/granmax-pu', //grandmax pu
  },
  {
    colors: ['#747474', '#b9010a', '#ffffff', '#3a3a3a'],
    url: '/daihatsu/rocky', //rocky
  },
  {
    colors: ['#000000', '#ffffff', '#c0c0c0', '#525252'],
    url: '/daihatsu/granmax-bv', //grandmax bv
  },
  {
    colors: ['#000000', '#c0c0c0', '#ffffff', '#ff0000', '#0000ff'],
    url: '/daihatsu/new-sirion', //sirion
  },
  {
    colors: ['#000000', '#ffffff', '#c0c0c0', '#2c2c2c'],
    url: '/daihatsu/luxio', //luxio
  },
  {
    colors: [
      '#000000',
      '#ffffff',
      '#474747',
      '#acb8c0',
      '#ffff00',
      '#da680f',
      '#e0115f',
      '#d92121',
    ],
    url: '/daihatsu/all-new-ayla', // all new alya
  },

  // === toyota cars ===
  {
    colors: ['#000000', '#6e696d', '#454547', '#a8abae', '#ffffff'],
    url: '/toyota/grand-new-avanza', //grand new avanza
  },
  {
    colors: [
      '#000000',
      '#ff0000',
      '#c27a14',
      '#fbfc00',
      '#ffffff',
      '#c4c4c4',
      '#9e9e9e',
    ],
    url: '/toyota/new-agya', //agya
  },
  {
    colors: [
      '#000000',
      ['#000000', '#fcfcfc'],
      '#fbf9f9',
      '#d6d6d6',
      '#696969',
    ],
    url: '/toyota/new-fortuner', //fortuner
  },
  {
    colors: ['#000000', '#e15d3f', '#9ea7ae', '#626768', '#ffffff'],
    url: '/toyota/new-sienta', //sienta
  },
  {
    colors: [
      '#000000',
      '#2f3639',
      '#767a7b',
      '#fcf7f7',
      '#646053',
      '#ba4933',
      '#b72b33',
    ],
    url: '/toyota/new-calya', //calya
  },
  {
    colors: ['#000000', '#9c9c9c', '#846d3c', '#ffffff', '#9e0000', '#6b0000'],
    url: '/toyota/all-new-rush', //rush
  },
  {
    colors: ['#000000', '#a8abae', '#dcdbdb'],
    url: '/toyota/all-new-veloz', //veloz
  },
  {
    colors: ['#000000', '#737373', '#949494', '#ffffff', '#462f26'],
    url: '/toyota/new-kijang-innova', //innova
  },
  {
    colors: ['#000000', '#737373', '#b1b6c3', '#c82841', '#9e8273', '#08225d'],
    url: '/toyota/all-new-vios', //vios
  },
  {
    colors: ['#000000', '#ffffff'],
    url: '/toyota/all-new-voxy', //all-new-voxy
  },
  {
    colors: [
      '#000000',
      '#888686',
      '#e60000',
      ['#000000', '#e60000'],
      '#9e9e9e',
      ['#000000', '#9e9e9e'],
      '#4b91b9',
      ['#000000', '#4b91b9'],
      '#ffffff',
      ['#000000', '#ffffff'],
      '#ffff00',
      ['#000000', '#ffff00'],
    ],
    url: '/toyota/raize', //raize
  },
  {
    colors: ['#000000', '#696969', '#9c9c9c', '#ffffff', '#5b2f24', '#c60101'],
    url: '/toyota/new-venturer', //venturer
  },
  {
    colors: [
      '#000000',
      '#ffffff',
      '#ed1c24',
      '#0c72a5',
      '#969394',
      '#4f4d4d',
      '#111146',
    ],
    url: '/toyota/gr-86', // gr 86
  },
  {
    colors: ['#000000', '#ffffff', '#e4dbdf'],
    url: '/toyota/all-new-c-hr', // ch-r
  },
  {
    colors: ['#000000', '#ffffff', '#e7e8e7', '#a5aaae', '#505c68', '#d14b4b'],
    url: '/toyota/all-new-corolla-altis', // corolla altis
  },
  {
    colors: ['#000000', '#ffffff', '#949494', '#737373', '#ed0e3a'],
    url: '/toyota/all-new-hilux', // hilux
  },
  {
    colors: ['#000000', '#ffffff', '#4b4038', '#d1d1d3'],
    url: '/toyota/all-new-camry', // camry
  },
  {
    colors: ['#000000', '#ffffff'],
    url: '/toyota/new-alphard', // alphard
  },
  {
    colors: [
      '#000000',
      '#87868d',
      '#503030',
      '#99989a',
      '#3b27ce',
      '#c7c7c7',
      '#b30000',
    ],
    url: '/toyota/all-new-corolla-cross', // corolla cross
  },
  {
    colors: ['#ffffff', '#c2282e'],
    url: '/toyota/dyna', // dyna
  },
  {
    colors: ['#ffffff', '#b4b4b6', '#e1d4cd'],
    url: '/toyota/hiace', // hiace
  },
  {
    colors: [
      '#000000',
      '#ffffff',
      '#c2c2c2',
      '#858585',
      '#cf1717',
      '#f8960d',
      '#e3f02d',
    ],
    url: '/toyota/new-yaris', // yaris
  },
  {
    colors: ['#000000', '#ffffff', '#a8abae', '#454547', '#6e696d'],
    url: '/toyota/all-new-avanza',
  },
  {
    colors: ['#000000', '#cfd5d6', '#525052', '#741e21'],
    url: '/toyota/land-cruiser',
  },
  {
    colors: ['#000000', '#ffffff'],
    url: '/toyota/vellfire',
  },
  {
    colors: ['#000000', '#a8abae', '#dcdbdb'],
    url: '/toyota/grand-new-veloz',
  },
  {
    colors: ['#ffffff', '#b4b4b6', '#e1d4cd'],
    url: '/toyota/the-all-new-hiace-premio',
  },
  {
    colors: [
      '#000000',
      '#ffffff',
      '#862724',
      '#ad6335',
      '#bdc1b5',
      '#61666d',
      '#061b5c',
      '#3c4556',
    ],
    url: '/toyota/supra',
  },
  {
    colors: ['#000000', '#ffffff', '#c1c4c7', '#3c4148'],
    url: '/toyota/all-new-bz4x',
  },
  {
    colors: ['#000000', '#ffffff', '#949494', '#737373', '#808080'],
    url: '/toyota/all-new-kijang-innova-zenix',
  },
  {
    colors: ['#000000', '#ffffff', '#9e0000', '#20392c', '#a7a8ad'],
    url: '/toyota/all-new-yaris-cross',
  },

  // === bmw cars ===
  {
    colors: ['#000000', '#ffffff', '#7e1818', '#3c4f86', '#787d7f', '#bec3c6'],
    url: '/bmw/3-series-320i-sport',
  },
  {
    colors: ['#060606', '#c4c4c4', '#9e2328', '#445c9a', '#585f61', '#505455'],
    url: '/bmw/3-series-330i-m-sport',
  },
  {
    colors: ['#060606', '#c4c4c4', '#2f2f2f', '#0f2646', '#585f61', '#585f61'],
    url: '/bmw/x3-xdrive30i-m-sport',
  },
  {
    colors: ['#1d1b17', '#d3d3d3', '#22293d', '#6d644f', '#585f61', '#505455'],
    url: '/bmw/x7-xdrive40i-opulence',
  },
  {
    colors: ['#000000', '#ffffff', '#7e1818', '#3c4f86', '#787d7f', '#bec3c6'],
    url: '/bmw/3-series-320i-m-sport',
  },
  {
    colors: ['#000000', '#ffffff', '#7e1818', '#3c4f86', '#787d7f', '#bec3c6'],
    url: '/bmw/3-series-330i-m-sport-pro',
  },
  {
    colors: [
      '#060606',
      '#2a2a2a',
      '#22293d',
      '#e1e1e1',
      '#878f92',
      '#0f2646',
      '#2f3858',
      '#a2a19b',
      '#524e4f',
    ],
    url: '/bmw/5-series-530i-opulence',
  },
  {
    colors: [
      '#181818',
      '#c4c4c4',
      '#262d30',
      '#777e86',
      '#33291f',
      '#463e28',
      '#1b314f',
      '#ec5a37',
      '#918b7d',
    ],
    url: '/bmw/x1-sdrive18i-sport',
  },
  {
    colors: ['#000000', '#ffffff', '#57595a', '#eaeaea', '#5c5a48', '#405b79'],
    url: '/bmw/x3-sdrive20i',
  },
  {
    colors: ['#181818', '#c4c4c4', '#787d7e', '#1f2633', '#33291f'],
    url: '/bmw/x5-xdrive40i-x-line',
  },
  {
    colors: [
      '#060606',
      '#c4c4c4',
      '#6a6358',
      '#2f2f2f',
      '#0f2646',
      '#4f5356',
      '#2f3858',
      '#a2a19b',
    ],
    url: '/bmw/7-series-730li-m-sport',
  },
  {
    colors: ['#060606', '#2f2f2f', '#c4c4c4', '#6a6358', '#0f2646', '#a2a19b'],
    url: '/bmw/7-series-740li-opulence',
  },
  {
    colors: [
      '#181818',
      '#c4c4c4',
      '#94999a',
      '#463e28',
      '#1b314f',
      '#ec5a37',
      '#918b7d',
      '#777e86',
    ],
    url: '/bmw/x1-sdrive18i-xline',
  },
  {
    colors: [
      '#060606',
      '#2a2a2a',
      '#22293d',
      '#e1e1e1',
      '#878f92',
      '#0f2646',
      '#2f3858',
      '#a2a19b',
      '#524e4f',
    ],
    url: '/bmw/5-series-520i-m-sport',
  },

  // === isuzu cars ===
  {
    colors: ['#ffffff', '#000000', '#ac412c'],
    url: '/isuzu/d-max',
  },
  {
    colors: ['#ffffff', '#000000'],
    url: '/isuzu/mu-x',
  },

  // === peugeot cars ===
  {
    colors: ['#0d0d0d', '#c0c1bb', '#20517a'],
    url: '/peugeot/5008',
  },
  {
    colors: ['#0d0d0d', '#c0c1bb'],
    url: '/peugeot/3008',
  },
  {
    colors: ['#0d0d0d', '#54554d', '#c0c1bb', '#de6e2b'],
    url: '/peugeot/2008',
  },
  {
    colors: ['#000000', '#ffffff', '#c2282e', '#737373', '#a7a8ad', '#d3ca50'],
    url: '/toyota/all-new-agya',
  },

  // === hyundai cars ===
  {
    colors: ['#121210', '#c6c1bd', '#4a4b4f', '#e2e2ea', '#939395'],
    url: '/hyundai/ioniq-5',
  },
  {
    colors: ['#0b101d', '#e9efe7', '#2d393c', '#9a9890', '#040508'],
    url: '/hyundai/ioniq-6',
  },
  {
    colors: ['#242527', '#2a3548', '#e9e9e9', '#494e50', '#949999'],
    url: '/hyundai/palisade',
  },
  {
    colors: ['#181717', '#999b9a', '#36383e', '#cfd0d7'],
    url: '/hyundai/staria',
  },
  {
    colors: ['#d0d0d0', '#a6a8a7', '#494949', '#262829', '#2d3337'],
    url: '/hyundai/santa-fe',
  },
  {
    colors: [
      ['#1b1d1c', '#868684'],
      ['#1b1d1c', '#862725'],
      ['#1b1d1c', '#f8f8f6'],
      '#0a0f14',
      '#eff1f1',
      '#888a8c',
      '#424b51',
      '#982020',
    ],
    url: '/hyundai/stargazer',
  },
  {
    colors: [
      ['#1b1d1c', '#868684'],
      ['#1b1d1c', '#862725'],
      ['#1b1d1c', '#f8f8f6'],
      '#0a0f14',
      '#eff1f1',
      '#888a8c',
      '#424b51',
      '#982020',
    ],
    url: '/hyundai/stargazer-x',
  },
  {
    colors: ['#0c1015', '#eef0ee', '#85878a', '#3d474c', '#981e1f'],
    url: '/hyundai/creta',
  },
]

export const availableList = availableListColors.map((item) => item.url)
