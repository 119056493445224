import { LanguageCode } from 'utils/enum'
import { formatNumberByLocalization } from 'utils/handler/rupiah'
import {
  comma,
  hundred,
  jt,
  million,
  point,
  Rp,
  ten,
  thousand,
} from 'utils/helpers/const'
import { COMMA, DOT } from 'utils/handler/string'

enum RoundingStrategy {
  Round,
  Ceil,
  Floor,
}

export const formatPriceNumber = (price: number) => {
  return Math.round(price / million)
}

export const formatPriceNumberThousand = (price: number) => {
  return Math.round(price / thousand)
}

export const formatPriceNumberThousandDivisor = (
  price: number,
  languageId: LanguageCode,
) => {
  if (languageId === LanguageCode.id) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  } else {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}

const formatNumberByDivisor = (
  length: number,
  divisor = thousand,
  digits = hundred,
  roundStrategy = RoundingStrategy.Round,
) => {
  if (roundStrategy === RoundingStrategy.Round) {
    return Math.round((length / divisor) * digits) / digits
  }
  if (roundStrategy === RoundingStrategy.Ceil) {
    return Math.ceil((length / divisor) * digits) / digits
  }
  return Math.floor((length / divisor) * digits) / digits
}

//  DecimalPoint: if LanguageCode is "id" use COMMA, if LanguageCode is "en"
//  use  DOT
const replaceDecimalPointByLocalization = (
  num: number,
  languageId: LanguageCode,
) => (languageId === LanguageCode.id ? num.toString().replace(DOT, COMMA) : num)

export const formatBillionPoint = (num: number | string) => {
  const strNum = String(num).split(',')
  if (strNum[0].length > 3)
    return String(num).slice(0, 1) + '.' + String(num).slice(1, 7)
  return num
}

// PriceSeparator from link header variant list
const replaceDecimalPointByLocalizationHeaderVariantList = (
  num: number,
  languageId: LanguageCode,
) =>
  languageId === LanguageCode.id
    ? num.toString().replace(COMMA, DOT)
    : num.toString().replace(DOT, COMMA)
