export const onlyLettersAndSpaces = (str: string) => {
  return /^[A-Za-z\s]*$/.test(str)
}

export const isIsoDateFormat = (date: string) => {
  const regEx = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
  return regEx.test(date)
}

export const RegExOnlyAlphanumericAndSpace = /^[\w\s]*$/
export const RegExOnlyAlphabetsAndSpaces = /^[a-zA-Z ]*$/
// Only Allow Apostrophe
export const RegExLongAlphabetAndSpaces =
  /[`!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?~]/

export const RegexName = /^[a-zA-Z .,']*$/

export const nameValidation = (name: string) => RegexName.test(name)
