import { OtrStatus } from 'utils/enum'
import { CarRecommendation } from 'utils/types/utils'

export const getFilteredVariantsWithLiveOtr = (variantsArray?: any[]) => {
  if (Array.isArray(variantsArray) && variantsArray?.length > 0) {
    return variantsArray?.filter((item: any) => {
      return (
        item?.otrInfo?.otrStatus === undefined || // if object isnt valid, treat as OTR is live
        item?.otrInfo?.otrStatus === OtrStatus.Live
      )
    })
  } else {
    return variantsArray
  }
}

export const getFilteredNewFunnelWithLiveOtr = (
  carRecommendationsArray?: CarRecommendation[],
) => {
  if (
    Array.isArray(carRecommendationsArray) &&
    carRecommendationsArray?.length > 0
  ) {
    return carRecommendationsArray?.filter((item: CarRecommendation) => {
      return (
        item?.hasLiveVariant === undefined || // if object isnt valid, treat as OTR is live
        item?.hasLiveVariant === true
      )
    })
  } else {
    return carRecommendationsArray
  }
}

export const isOtrStatusVariantLive = (otrStatus?: OtrStatus): boolean => {
  if (
    otrStatus !== undefined &&
    (otrStatus === OtrStatus.Draft || otrStatus === OtrStatus.Waiting)
  ) {
    return false
  } else {
    return true // if object isnt valid, treat as OTR is live
  }
}

export const isOtrStatusModelLive = (hasLiveVariant?: boolean): boolean => {
  if (hasLiveVariant !== undefined && hasLiveVariant === false) {
    return false
  } else {
    return true // if object isnt valid, treat as OTR is live
  }
}
