import { testPhoneNumber } from 'utils/helpers/const'
import {
  HTTPResponseStatusCode,
  LocalStorageKey,
  WhatsappOtpCheckStatus,
} from 'utils/enum'
import ReportSentry from 'utils/handler/sentry'
import {
  getOtpWhatsappCheckStatus,
  postSendOtpFromSms,
  postSendOtpFromWhatsapp,
} from 'services/api'
import { OtpV2ReceiverType } from 'components/organisms/otpV2'

const delayBeforeFetchWhatsappCheckStatus = 2 * 1000 // 2s

export const saveOtpIsSent = (value: string) => {
  localStorage.setItem(LocalStorageKey.OtpIsSent, value)
}

export const saveOtpTimerIsStart = (value: string) => {
  localStorage.setItem(LocalStorageKey.OtpTimerIsStart, value)
}

const sendOtpCodeByWhatsappWithCheckStatus = async (phoneNumber: string) => {
  try {
    const responseOtp = await postSendOtpFromWhatsapp(
      `+62${phoneNumber}`,
      undefined,
      true, // prevent navigate to error page
    )

    if (responseOtp?.message === 'OTP Sent Success') {
      // need to sleep for 1s before fetching API check status
      await new Promise((resolve) =>
        setTimeout(resolve, delayBeforeFetchWhatsappCheckStatus),
      )
      const whatsappStatusResponse = await getOtpWhatsappCheckStatus(
        responseOtp?.wamid,
        undefined,
        true, // prevent navigate to error page
      )
      return whatsappStatusResponse
    } else {
      return null
    }
  } catch (error: any) {
    ReportSentry(`OTP - sendOtpCodeByWhatsappWithCheckStatus`, error)
    return error
  }
}

const sendOtpCodeBySms = async (phoneNumber: string) => {
  try {
    const response = await postSendOtpFromSms(`+62${phoneNumber}`)
    if (!!response) {
      return response
    } else {
      return null
    }
  } catch (error: any) {
    ReportSentry(`OTP - sendOtpCodeBySms`, error)
    return error
  }
}

const isOtpSuccessReceivedThroughWhatsapp = (status?: string) => {
  return (
    status === WhatsappOtpCheckStatus.Sent ||
    status === WhatsappOtpCheckStatus.Read ||
    status === WhatsappOtpCheckStatus.Delivered
  )
}

export const sendOtpCodeByWhatsappAndSms = async (
  phoneNumber: string,
  onSuccessRequestOtp?: (receiver: OtpV2ReceiverType, responseApi: any) => void,
  onFailedTooManyRequest?: (errorObject: any) => void,
  onGeneralFailed?: (errorObject: any) => void,
) => {
  try {
    const sendOtpCodeByWhatsappWithCheckStatusResponse =
      await sendOtpCodeByWhatsappWithCheckStatus(phoneNumber)

    if (
      isOtpSuccessReceivedThroughWhatsapp(
        sendOtpCodeByWhatsappWithCheckStatusResponse?.status,
      )
    ) {
      return (
        onSuccessRequestOtp &&
        onSuccessRequestOtp(
          'whatsapp',
          sendOtpCodeByWhatsappWithCheckStatusResponse,
        )
      )
    } else {
      // any error in requesting whatsapp, will send request OTP through SMS
      const sendOtpCodeBySmsResponse = await sendOtpCodeBySms(phoneNumber)
      if (sendOtpCodeBySmsResponse?.message === 'OTP Sent Success') {
        onSuccessRequestOtp &&
          onSuccessRequestOtp('sms', sendOtpCodeBySmsResponse)
      } else {
        throw sendOtpCodeBySmsResponse
      }
    }
  } catch (error: any) {
    ReportSentry(`OTP - sendOtpCodeByWhatsappAndSms`, error)
    if (
      (error.response?.status as HTTPResponseStatusCode) ===
      HTTPResponseStatusCode.TooManyRequest
    ) {
      onFailedTooManyRequest && onFailedTooManyRequest(error)
    } else {
      onGeneralFailed && onGeneralFailed(error)
    }
  }
}
