import React, { useEffect, useState } from 'react'
import styles from '/styles/components/organisms/leadsFormPrimary.module.scss'
import { Button, Gap, IconLoading, Input, InputPhone } from 'components/atoms'
import { LocalStorageKey, UnverifiedLeadSubCategory } from 'utils/enum'
import { getLocalStorage, saveLocalStorage } from 'utils/handler/localStorage'
import { decryptValue, encryptValue } from 'utils/handler/encryption'
import { filterNonDigitCharacters, replaceIndex0 } from 'utils/handler/string'
import elementId from 'utils/helpers/elementId/elementIds'
import { Modal } from 'components/atoms'
import {
  LeadsActionParam,
  trackLeadsFormAction,
} from 'services/amplitude/seva20Tracking'
import { TrackingEventName } from 'services/amplitude/types'
import { useMediaQuery } from 'react-responsive'
import { onlyLettersAndSpaces } from 'utils/handler/regex'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { OTP } from 'components/organisms/otp'
import Image from 'next/image'
import { CityOtrOption } from 'utils/types'
import { ButtonSize, ButtonVersion } from 'components/atoms/button'
import {
  getTemanSevaStatus,
  trackEventCountly,
  valueForUserTypeProperty,
  valueMenuTabCategory,
} from 'services/countly/countly'
import { CountlyEventNames } from 'services/countly/eventNames'
import { getToken } from 'utils/handler/auth'
import { getCustomerInfoSeva } from 'utils/handler/customer'
import {
  createUnverifiedLeadNew,
  trackMoengageSubmitLeads,
} from 'utils/handler/lead'
import { useCar } from 'services/context/carContext'
import { useUtils } from 'services/context/utilsContext'
import ReportSentry from 'utils/handler/sentry'
import dynamic from 'next/dynamic'
import { OtpV2ReceiverType } from 'components/organisms/otpV2'
import { Wording } from 'utils/helpers/glosary/glosary'
import { sendOtpCodeByWhatsappAndSms } from 'utils/handler/otp'

const OTPV2 = dynamic(
  () => import('components/organisms').then((mod) => mod.OTPV2),
  { ssr: false },
)

const Toast = dynamic(
  () => import('components/atoms').then((mod) => mod.Toast),
  { ssr: false },
)

const SupergraphicSecondarySmall =
  '/revamp/illustration/supergraphic-secondary-small.webp'
const SupergraphicSecondaryLarge =
  '/revamp/illustration/supergraphic-secondary-large.webp'

interface PropsLeadsForm {
  otpStatus?: any
  onVerify?: (e: any) => void
  onFailed?: (e: any) => void
  onCancel?: () => void
  trackerProperties?: LeadsActionParam
  onPage?: string
  isProduct?: boolean
}

export const AdaOTOdiSEVALeadsForm: React.FC<PropsLeadsForm> = ({
  onCancel,
  trackerProperties,
  onPage,
  isProduct = false,
}: PropsLeadsForm) => {
  const platform = 'web'
  const toastSuccessInfo = 'Agen kami akan segera menghubungimu dalam 1x24 jam.'
  const [name, setName] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [phone, setPhone] = useState<string>('')
  const [isFilled, setIsFilled] = useState<boolean>(false)
  const [modalOpened, setModalOpened] = useState<
    'leads-form' | 'otp' | 'success-toast' | 'none'
  >('leads-form')
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false)
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const { carModelDetails, carVariantDetails } = useCar()
  const { dataLeads, dataVariantLeads } = useUtils()
  const [cityOtr] = useLocalStorage<CityOtrOption | null>(
    LocalStorageKey.CityOtr,
    null,
  )
  const [otpV2Receiver, setOtpV2Receiver] =
    useState<OtpV2ReceiverType>('whatsapp')
  const [isOpenToastOtpV2, setIsOpenToastOtpV2] = useState(false)
  const [toastMessageOtpV2, setToastMessageOtpV2] = useState(
    Wording.CommonError,
  )
  const { setLastOtpSentTime } = useUtils()
  const [, setLastOptSentPhoneNumber] = useLocalStorage<string>(
    LocalStorageKey.LastOtpSentPhoneNumber,
    '',
  )

  const handleInputName = (payload: any): void => {
    if (payload !== ' ' && onlyLettersAndSpaces(payload)) {
      setName(payload)
      checkInputValue(payload, phone)
    }
  }

  const handleInputPhone = (payload: any): void => {
    const temp = filterNonDigitCharacters(payload)
    if (temp[0] === '8' || temp === '') {
      setPhone(temp)
      checkInputValue(name, temp)
    }
  }

  const checkInputValue = (name: string, phone: string): void => {
    if (name !== '' && phone?.length > 8) setIsFilled(true)
    else setIsFilled(false)
  }

  useEffect(() => {
    getDataCustomer()
  }, [])

  const verified = () => {
    const data = {
      name,
      phone,
    }
    saveFlagLeads(data)
    sendUnverifiedLeads()
  }

  const trackCountlyOtpView = () => {
    trackCountlySendLeads('No')
    trackEventCountly(CountlyEventNames.WEB_OTP_VIEW, {
      PAGE_ORIGINATION: 'PLP',
    })
  }

  const onSuccessRequestOtpV2Handler = (
    receiver: OtpV2ReceiverType,
    responseApi: any,
  ) => {
    setIsLoading(false)
    setLastOtpSentTime(Date.now())
    setLastOptSentPhoneNumber(encryptValue(phone.toString()))
    setOtpV2Receiver(receiver)
    trackCountlyOtpView()
    setModalOpened('otp')
  }

  const onFailedTooManyRequestV2Handler = (errorObject: any) => {
    setIsLoading(false)
    setToastMessageOtpV2(Wording.OtpTooManyRequest)
    setIsOpenToastOtpV2(true)
    setTimeout(() => {
      setIsOpenToastOtpV2(false)
    }, 3000)
  }

  const onGeneralFailedV2Handler = (errorObject: any) => {
    setIsLoading(false)
    setToastMessageOtpV2(Wording.CommonError)
    setIsOpenToastOtpV2(true)
    setTimeout(() => {
      setIsOpenToastOtpV2(false)
    }, 3000)
  }

  const requestOtpV2Handler = () => {
    sendOtpCodeByWhatsappAndSms(
      phone,
      onSuccessRequestOtpV2Handler,
      onFailedTooManyRequestV2Handler,
      onGeneralFailedV2Handler,
    )
  }

  const sendOtpCode = async () => {
    setIsLoading(true)
    if (trackerProperties)
      trackLeadsFormAction(
        TrackingEventName.WEB_LEADS_FORM_SUBMIT,
        trackerProperties,
      )
    const dataLeads = checkDataFlagLeads()
    if (dataLeads) {
      if (phone === dataLeads.phone && name === dataLeads.name) {
        trackCountlySendLeads('Yes')
        sendUnverifiedLeads()
      } else if (phone === dataLeads.phone && name !== dataLeads.name) {
        trackCountlySendLeads('Yes')
        sendUnverifiedLeads()
        updateFlagLeadsName(name)
      } else {
        requestOtpV2Handler()
      }
    } else if (isUserLoggedIn) {
      sendUnverifiedLeads()
    } else {
      requestOtpV2Handler()
    }
  }

  const saveFlagLeads = (payload: any) => {
    const now = new Date()
    const expiry = now.getTime() + 7 * 24 * 60 * 60 * 1000
    const data = { ...payload, expiry }
    const encryptedData = encryptValue(JSON.stringify(data))
    saveLocalStorage(LocalStorageKey.flagLeads, encryptedData)
  }

  const updateFlagLeadsName = (payload: string) => {
    const data: any = getLocalStorage(LocalStorageKey.flagLeads)
    const decryptedValue: any = JSON.parse(decryptValue(data))
    const newData = { ...decryptedValue, name: payload }
    const encryptedData = encryptValue(JSON.stringify(newData))
    saveLocalStorage(LocalStorageKey.flagLeads, encryptedData)
  }

  const trackCountlySendLeads = async (verifiedPhone: string) => {
    let temanSevaStatus = 'No'
    let pageOrigination = 'PLP'

    if (onPage === 'LP') {
      pageOrigination = 'PLP'
    }
    trackEventCountly(CountlyEventNames.WEB_LEADS_FORM_SEND_CLICK, {
      PAGE_ORIGINATION: 'PLP',
      LOGIN_STATUS: isUserLoggedIn ? 'Yes' : 'No',
      PHONE_VERIFICATION_STATUS: verifiedPhone,
      PHONE_NUMBER: '+62' + phone,
    })
  }
  const onClose = () => {
    if (trackerProperties)
      trackLeadsFormAction(
        TrackingEventName.WEB_LEADS_FORM_CLOSE,
        trackerProperties,
      )
    onCancel && onCancel()
  }

  const sendUnverifiedLeads = async () => {
    let data
    let pageOrigination = 'PLP'
    const referralCodeFromUrl: string | null = getLocalStorage(
      LocalStorageKey.referralTemanSeva,
    )

    if (onPage === 'LP') {
      pageOrigination = 'PLP'
    }

    const temanSevaStatus = await getTemanSevaStatus(referralCodeFromUrl)

    if (onPage === 'LP' && isProduct) {
      data = {
        origination: UnverifiedLeadSubCategory.OTO_NEW_CAR_LP_LEADS_FORM,
        name,
        phoneNumber: phone,
        ...(cityOtr?.id && { cityId: cityOtr.id }),
        platform,
      }
    } else if (onPage === 'LP') {
      data = {
        origination: UnverifiedLeadSubCategory.OTO_NEW_CAR_PLP_LEADS_FORM,
        name,
        phoneNumber: phone,
        ...(cityOtr?.id && { cityId: cityOtr.id }),
        platform,
        carBrand: dataLeads?.brand,
        carModelText: dataLeads?.model,
      }
    } else if (onPage === 'PLP' && isProduct) {
      data = {
        origination: UnverifiedLeadSubCategory.OTO_NEW_CAR_PLP_LEADS_FORM,
        name,
        phoneNumber: phone,
        ...(cityOtr?.id && { cityId: cityOtr.id }),
        platform,
      }
    } else if (onPage === 'PLP') {
      data = {
        origination: UnverifiedLeadSubCategory.OTO_NEW_CAR_PLP_LEADS_FORM,
        name,
        phoneNumber: phone,
        ...(cityOtr?.id && { cityId: cityOtr.id }),
        platform,
        carBrand: dataLeads?.brand,
        carModelText: dataLeads?.model,
      }
    } else if (onPage === 'PDP' && isProduct) {
      data = {
        origination: UnverifiedLeadSubCategory.OTO_NEW_CAR_PDP_LEADS_FORM,
        name,
        phoneNumber: phone,
        ...(cityOtr?.id && { cityId: cityOtr.id }),
        platform,
        carBrand: carModelDetails?.brand,
        carModelText: carModelDetails?.model,
      }
    } else if (onPage === 'PDP') {
      data = {
        origination: UnverifiedLeadSubCategory.OTO_NEW_CAR_PDP_LEADS_FORM,
        name,
        phoneNumber: phone,
        ...(cityOtr?.id && { cityId: cityOtr.id }),
        platform,
        carBrand: carModelDetails?.brand,
        carModelText: carModelDetails?.model,
        carVariantText: dataVariantLeads,
      }
    }
    try {
      setIsLoading(true)
      await createUnverifiedLeadNew(data)
      if (trackerProperties)
        trackLeadsFormAction(
          TrackingEventName.WEB_LEADS_FORM_SUCCESS,
          trackerProperties,
        )
      trackEventCountly(CountlyEventNames.WEB_LEADS_FORM_SUCCESS_VIEW, {
        PAGE_ORIGINATION: 'PLP',
        LOGIN_STATUS: isUserLoggedIn ? 'Yes' : 'No',
        TEMAN_SEVA_STATUS: temanSevaStatus,
        PHONE_NUMBER: '+62' + phone,
      })
      setIsLoading(false)
      trackMoengageSubmitLeads(name, phone)
      setModalOpened('success-toast')
      setTimeout(() => {
        setModalOpened('none')
        onCancel && onCancel()
      }, 3000)
    } catch (error) {
      setIsLoading(false)
      ReportSentry(
        `LeadsForm Ada OTO di Seva Pop Up - sendUnverifiedLeads`,
        error,
      )
    }
  }

  const getDataCustomer = async () => {
    const data: string | null = getLocalStorage(LocalStorageKey.sevaCust)
    if (data !== null) {
      const user = JSON.parse(decryptValue(data))
      setName(user.fullName)
      setPhone(user.phoneNumber.replace('+62', ''))
      setIsUserLoggedIn(true)
      setIsFilled(true)
    } else {
      const dataLeads = checkDataFlagLeads()
      if (dataLeads) {
        setName(dataLeads.name)
        setPhone(dataLeads.phone)
        setIsFilled(true)
      }
    }
  }

  const checkDataFlagLeads = () => {
    const now = new Date()
    const flagLeads: any | null = getLocalStorage(LocalStorageKey.flagLeads)
    if (flagLeads !== null) {
      const parsedLeads = JSON.parse(decryptValue(flagLeads))
      if (now.getTime() > parsedLeads.expiry) {
        localStorage.removeItem(LocalStorageKey.flagLeads)
        return
      } else {
        return parsedLeads
      }
    }
  }

  const onClickNameField = () => {
    if (onPage === 'LP') {
      trackEventCountly(CountlyEventNames.WEB_LEADS_FORM_NAME_CLICK, {
        PAGE_ORIGINATION: 'PLP',
        USER_TYPE: valueForUserTypeProperty(),
      })
    }
  }

  const onClickPhoneField = () => {
    if (onPage === 'LP') {
      trackEventCountly(CountlyEventNames.WEB_LEADS_FORM_PHONE_NUMBER_CLICK, {
        PAGE_ORIGINATION: 'PLP',
        USER_TYPE: valueForUserTypeProperty(),
      })
    }
  }
  return (
    <>
      {modalOpened === 'leads-form' && (
        <Modal open onCancel={onClose} isFull>
          <div className={styles.wrapper}>
            <div className={styles.background}>
              <div className={styles.wrapperSupergraphicSmall}>
                <Image
                  src={SupergraphicSecondarySmall}
                  alt="seva-vector-blue-rounded"
                  width={200}
                  height={140}
                  className={styles.supergraphicSmall}
                />
              </div>
              <div className={styles.wrapperSupergraphicLarge}>
                <Image
                  src={SupergraphicSecondaryLarge}
                  alt="seva-vector-red-rounded"
                  width={343}
                  height={428}
                  className={styles.supergraphicLarge}
                />
              </div>
            </div>
            <div className={styles.foreground}>
              <h2 className={styles.textHeading}>Tim Kami Siap Membantumu</h2>
              <p className={styles.textDesc}>
                Untuk tahu lebih lanjut, yuk ngobrol dengan <br /> tim kami.
                Kami akan menghubungi kamu <br /> dalam 1x24 jam.
              </p>
              <div className={styles.form}>
                <Input
                  dataTestId={elementId.PLP.LeadsForm.FullName}
                  placeholder="Masukkan nama lengkap"
                  title="Nama Lengkap"
                  value={name}
                  onChange={(e: any) => handleInputName(e.target.value)}
                  onFocus={onClickNameField}
                />
                <Gap height={24} />
                <InputPhone
                  dataTestId={elementId.PLP.LeadsForm.PhoneNumber}
                  disabled={isUserLoggedIn}
                  placeholder="Contoh: 812345678"
                  title="Nomor HP"
                  value={phone}
                  onChange={(e: any) => handleInputPhone(e.target.value)}
                  onFocus={onClickPhoneField}
                />
                <Gap height={32} />
                <Button
                  data-test-id={elementId.PLP.Button.KirimLeads}
                  disabled={!isFilled}
                  version={
                    isFilled
                      ? ButtonVersion.PrimaryDarkBlue
                      : ButtonVersion.Disable
                  }
                  size={ButtonSize.Big}
                  onClick={() => sendOtpCode()}
                >
                  {isLoading && isFilled ? (
                    <div className={`${styles.iconWrapper} rotateAnimation`}>
                      <IconLoading width={14} height={14} color="#FFFFFF" />
                    </div>
                  ) : (
                    'Kirim'
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {modalOpened === 'otp' && (
        <OTPV2
          isOpened
          phoneNumber={phone}
          closeModal={onCancel}
          isOtpVerified={() => verified()}
          pageOrigination={
            onPage === 'LP' ? 'PLP' : 'PDP - ' + valueMenuTabCategory()
          }
          receiver={otpV2Receiver}
        />
      )}
      <Toast
        text={toastSuccessInfo}
        width={isMobile ? 339 : 428}
        open={modalOpened === 'success-toast'}
        data-testid={elementId.PLP.Text.SuccessToastMessageLeads}
      />
      <Toast
        typeToast="error2"
        text={toastMessageOtpV2}
        width={343}
        open={isOpenToastOtpV2 && !!toastMessageOtpV2}
      />
    </>
  )
}
