import { LocalStorageKey } from 'utils/enum'
import { getLocalStorage } from 'utils/handler/localStorage'
import { dpRateCollectionNewCalculator } from 'utils/helpers/const'
import { InstallmentTypeOptions } from 'utils/types/models'
import {
  CalculatorResultV3,
  PromoItemType,
  PromoItemTypeV3,
  SpecialRateListWithPromoType,
  SpecialRateListWithPromoTypeV3,
  UTMTagsData,
  dpRateCollectionNewCalculatorTmp,
} from 'utils/types/utils'

const regularCalculatorName = 'reguler'

export const generateAllBestPromoList = (list: PromoItemType[]) => {
  return list.map((item) => {
    // all promos from this new loan calculator api, will return best promo
    return {
      ...item,
      is_Best_Promo: true,
      is_Available: true,
    }
  })
}

export const generateAllBestPromoListV3 = (list: PromoItemTypeV3[]) => {
  return list.map((item) => {
    // all promos from this new loan calculator api, will return best promo
    return {
      ...item,
      is_Best_Promo: true,
      is_Available: true,
    }
  })
}

// func getTdpAffectedByPromo() only used everytime API calculate is called
export const getTdpAffectedByPromo = (
  currentPermutation: SpecialRateListWithPromoType,
) => {
  if (currentPermutation?.applied.toLowerCase().includes('giias')) {
    return (
      currentPermutation?.totalFirstPaymentGiias -
      currentPermutation?.subsidiDp -
      currentPermutation?.dpDiscount
    )
  } else if (currentPermutation?.applied.toLowerCase().includes('spekta')) {
    return (
      currentPermutation?.totalFirstPaymentSpekta -
      currentPermutation?.subsidiDp -
      currentPermutation?.dpDiscount
    )
  } else if (
    currentPermutation?.subsidiDp !== 0 &&
    currentPermutation.promoArr.length !== 0
  ) {
    // if promoArr empty nominal will use total first payment regular only
    return (
      currentPermutation?.totalFirstPayment -
      currentPermutation?.subsidiDp -
      currentPermutation?.dpDiscount
    )
  } else if (currentPermutation?.dpDiscount !== 0) {
    return (
      currentPermutation?.totalFirstPayment -
      currentPermutation?.subsidiDp -
      currentPermutation?.dpDiscount
    )
  } else {
    return 0
  }
}

export const getTdpAfterPromoV3 = (
  currentPermutation: SpecialRateListWithPromoTypeV3,
  dpDiscountVariant: number = 0,
) => {
  const temp =
    getCalculatorTdpWithAllPromo(currentPermutation?.lowestCalculator) -
    dpDiscountVariant
  return getTdpFromCalculatorRegular(currentPermutation?.calculatorArr) !== temp
    ? temp
    : 0
}

export const getCalculatorTdpWithAllPromo = (
  calculator: CalculatorResultV3,
) => {
  const totalPromo = calculator?.promoArr
    ?.filter(
      (promo) =>
        promo.promo_type?.toLowerCase() === 'subsidi dp' ||
        promo.promo_type?.toLowerCase() === 'bebas administrasi',
    )
    .reduce((total, value) => total + value.promo_amount, 0)
  return calculator?.totalFirstPayment - totalPromo
}

export const getCalculatorTdpWithSelectedPromo = (
  calculator: CalculatorResultV3,
  promoArr: PromoItemTypeV3[],
) => {
  const totalPromo = promoArr
    .filter(
      (promo) =>
        promo.promo_type?.toLowerCase() === 'subsidi dp' ||
        promo.promo_type?.toLowerCase() === 'bebas administrasi',
    )
    .reduce((total, value) => total + value.promo_amount, 0)
  return calculator?.totalFirstPayment - totalPromo
}

export const getTdpFromCalculatorRegular = (
  calculatorArr: CalculatorResultV3[],
) => {
  const calculatorRegular = calculatorArr?.filter(
    (item) => item.calculator_name?.toLowerCase() === regularCalculatorName,
  )
  return calculatorRegular[0]?.totalFirstPayment ?? 0
}

export const getInstallmentAffectedByPromo = (
  currentPermutation: SpecialRateListWithPromoType,
) => {
  if (currentPermutation?.applied.toLowerCase().includes('giias')) {
    return currentPermutation?.installmentGiias
  } else if (currentPermutation?.applied.toLowerCase().includes('spekta')) {
    return currentPermutation?.installmentSpekta
  } else {
    return 0
  }
}

export const getInstallmentAffectedByPromoV3 = (
  currentPermutation: SpecialRateListWithPromoTypeV3,
) => {
  return getInstallmentFromCalculatorRegular(
    currentPermutation?.calculatorArr,
  ) !== currentPermutation?.lowestCalculator?.installment
    ? currentPermutation?.lowestCalculator?.installment
    : 0
}

export const getInstallmentFromCalculatorRegular = (
  calculatorArr: CalculatorResultV3[],
) => {
  const calculatorRegular = calculatorArr?.filter(
    (item) => item.calculator_name?.toLowerCase() === regularCalculatorName,
  )
  return calculatorRegular[0]?.installment ?? 0
}

export const getInterestRateAffectedByPromo = (
  currentPermutation: SpecialRateListWithPromoType,
) => {
  if (currentPermutation?.applied.toLowerCase().includes('giias')) {
    return currentPermutation?.interestRateGiias
  } else if (currentPermutation?.applied.toLowerCase().includes('spekta')) {
    return currentPermutation?.interestRateSpekta
  } else {
    return 0
  }
}

export const getInterestRateAffectedByPromoV3 = (
  currentPermutation: SpecialRateListWithPromoTypeV3,
) => {
  return currentPermutation?.lowestCalculator?.interestRate
}

export const getInterestRateFromCalculatorRegular = (
  calculatorArr: CalculatorResultV3[],
) => {
  const calculatorRegular = calculatorArr?.filter(
    (item) => item.calculator_name?.toLowerCase() === regularCalculatorName,
  )
  return calculatorRegular[0]?.interestRate ?? 0
}

export const getFilteredCalculationResults = (
  calculationResult: any,
  isSelectPassengerCar: boolean,
) => {
  const tempArr = calculationResult
  if (!isSelectPassengerCar) {
    const fiveYearIndex = tempArr.findIndex((item: any) => item.tenure == 5)
    if (fiveYearIndex !== -1) {
      tempArr.splice(fiveYearIndex, 1)
    }
  }

  return tempArr
}

export const getDpPercentageByMapping = (dpValue: number, carOtr: number) => {
  const dpOtr = dpRateCollectionNewCalculator.map(
    (dp: dpRateCollectionNewCalculatorTmp) => {
      return {
        ...dp,
        dpCalc: dp.dpCalc * carOtr,
      }
    },
  )

  if (dpValue >= carOtr * 0.5) {
    return 50
  } else {
    for (let i = 0; i < 8; i++) {
      if (dpValue >= dpOtr[i].dpCalc && dpValue < dpOtr[i + 1].dpCalc) {
        return dpOtr[i].dpRate
      }
    }
  }

  return 20 //default
}

export const isExcludePromoS01 = () => {
  const UTMTags = getLocalStorage<UTMTagsData>(LocalStorageKey.UtmTags)

  const utmSource = UTMTags?.utm_source
  const utmMedium = UTMTags?.utm_medium

  if (
    !!utmSource &&
    utmSource?.length > 0 &&
    (utmSource?.substring(0, 4) === 'TSO_' ||
      utmSource?.substring(0, 4) === 'DSO_')
  ) {
    return true
  } else if (
    !!utmMedium &&
    utmMedium?.length > 0 &&
    utmMedium?.includes('PA_Ambassador')
  ) {
    return true
  } else {
    return false
  }
}

export const getListFilteredPromoToBeGenerated = (
  list: PromoItemType[],
  option?: {
    isExcludeCDS01?: boolean
    isExcludeCDS02?: boolean
    isExcludeS01?: boolean
  },
) => {
  let tempList = list

  if (option?.isExcludeCDS01) {
    tempList = tempList.filter((item) => item.promoId !== 'CDS01')
  }
  if (option?.isExcludeCDS02) {
    tempList = tempList.filter((item) => item.promoId !== 'CDS02')
  }
  if (option?.isExcludeS01) {
    tempList = tempList.filter((item) => item.promoId !== 'S01')
  }

  return tempList
}

export const getTotalMonthInstallment = (
  tenure?: number,
  angsuranType?: InstallmentTypeOptions,
) => {
  if (!!tenure && !!angsuranType) {
    if (angsuranType === InstallmentTypeOptions.ADDM) {
      return tenure * 12 - 1
    } else {
      return tenure * 12
    }
  }
  return ''
}

export const addCalculatorNameToPromo = (
  res: SpecialRateListWithPromoTypeV3,
) => {
  let tempObj = JSON.parse(
    JSON.stringify(res),
  ) as SpecialRateListWithPromoTypeV3
  if (tempObj.lowestCalculator) {
    tempObj.lowestCalculator.promoArr = tempObj.lowestCalculator?.promoArr.map(
      (val) => ({
        ...val,
        calculatorName: tempObj.lowestCalculator?.calculator_name,
        calculatorId: tempObj.lowestCalculator?.calculator_id,
      }),
    )
  }
  tempObj.calculatorArr.forEach((calculator, index) => {
    tempObj.calculatorArr[index].promoArr = calculator.promoArr.map((val) => ({
      ...val,
      calculatorName: calculator.calculator_name,
      calculatorId: calculator.calculator_id,
    }))
  })

  return tempObj
}

export const getPromoDifferenceInPercentage = (
  newValue: number,
  oldValue: number,
) => {
  const temp = 100 - (newValue / oldValue) * 100
  return temp.toString()[0] !== '0' ? Math.round(temp) : 1
}

export const insuranceToText = (code: string): string => {
  const insuranceLength = code.length
  if (insuranceLength > 3) {
    code = code.slice(0, 2) + ' + ' + code.slice(2)
  }
  const textDict = {
    F: 'Full ',
    C: 'Comprehensive',
    T: 'TLO',
  }

  code = code.replace(/(F|C|T)/g, function (x) {
    return textDict[x as 'F' | 'C' | 'T']
  })
  code = code.replace(/(\d{1})/g, '$1 Tahun ')
  return code
}
