import React, { useEffect } from 'react'
import styles from 'styles/components/organisms/articleListCard.module.scss'
import Image from 'next/image'
import { Article } from 'utils/types'
import { articleDateFormat } from 'utils/handler/date'
import { LanguageCode, SessionStorageKey } from 'utils/enum'
import { trackEventCountly } from 'services/countly/countly'
import { CountlyEventNames } from 'services/countly/eventNames'
import { saveSessionStorage } from 'utils/handler/sessionStorage'
import { defineRouteName } from 'utils/navigate'
import Link from 'next/link'

type ArticlesWidgetProps = {
  article: Article
  currentTab?: string
  articleOrder: number
}
const ArticleWidgetListCard = ({
  article,
  currentTab,
  articleOrder,
}: ArticlesWidgetProps) => {
  const handleClickCTRL = (e: any, article: Article) => {
    e.preventDefault()
    e.stopPropagation()
    trackEventCountly(CountlyEventNames.WEB_ARTICLE_CLICK, {
      PAGE_ORIGINATION: 'Homepage',
      ARTICLE_SECTION: 'Carousel',
      ARTICLE_ORDER: articleOrder + 1,
      ARTICLE_CATEGORY: currentTab?.replace('&', 'dan'),
      PAGE_DIRECTION_URL: article.url,
    })
    saveSessionStorage(
      SessionStorageKey.PageReferrerBlog,
      defineRouteName(window.location.pathname + window.location.search),
    )
    if (e.button === 0 && e.ctrlKey) {
      window.open(article.url, '_blank')
    } else {
      window.location.href = article.url
    }
  }
  const handleMiddleRightClick = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.button === 1 || e.button === 2) {
      trackEventCountly(CountlyEventNames.WEB_ARTICLE_CLICK, {
        PAGE_ORIGINATION: 'Homepage',
        ARTICLE_SECTION: 'Carousel',
        ARTICLE_ORDER: articleOrder + 1,
        ARTICLE_CATEGORY: currentTab?.replace('&', 'dan'),
        PAGE_DIRECTION_URL: article.url,
      })
      saveSessionStorage(
        SessionStorageKey.PageReferrerBlog,
        defineRouteName(window.location.pathname + window.location.search),
      )
    }
  }
  return (
    <Link
      href={article.url}
      className={styles.cardArticle}
      onClick={(e) => {
        handleClickCTRL(e, article)
      }}
      onMouseDown={(e) => handleMiddleRightClick(e)}
    >
      <Image
        alt="list-article-seva"
        src={article.featured_image}
        width={143}
        height={108}
        style={{
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px',
          height: '108px',
          width: '143px',
          objectFit: 'cover',
        }}
        loading="lazy"
      />
      {article.category && (
        <div className={styles.categoryTextWrapper}>
          <p>
            {article.category.length > 19
              ? article.category.slice(0, 16) + '...'
              : article.category}
          </p>
        </div>
      )}
      <div className={styles.descriptionWrapper}>
        <p className={styles.textDate}>
          {article.publish_date
            ? articleDateFormat(new Date(article.publish_date), LanguageCode.id)
            : ''}
        </p>
        <h3 className={styles.textTitle}>{article.title}</h3>
      </div>
    </Link>
  )
}
export default ArticleWidgetListCard
