import {
  CarModelBasicDetailsResponse,
  CarModelDetailsResponse,
  CarRecommendation,
  SpecialRateListWithPromoTypeV3,
} from 'utils/types/utils'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { getCity } from 'utils/hooks/useCurrentCityOtr/useCurrentCityOtr'
import {
  getCarCatalog,
  getCarModelDetails,
  getCarVariantDetails,
} from 'services/api'
import ReportSentry from 'utils/handler/sentry'

export const getCarModelDetailsById = (
  id: string,
  config?: AxiosRequestConfig,
  ignoreErrorHandlerNavigation?: boolean,
) => {
  const params = new URLSearchParams()
  getCity().cityCode && params.append('city', getCity().cityCode as string)
  getCity().id && params.append('cityId', getCity().id as string)
  return getCarModelDetails(id, '', { params }, ignoreErrorHandlerNavigation)
}

export const getCarVariantDetailsById = (
  id: string,
  config?: AxiosRequestConfig,
  ignoreErrorHandlerNavigation?: boolean,
) => {
  const params = new URLSearchParams()
  getCity().cityCode && params.append('city', getCity().cityCode as string)
  getCity().id && params.append('cityId', getCity().id as string)
  return getCarVariantDetails(id, '', { params }, ignoreErrorHandlerNavigation)
}

export const mergeModelDetailsWithLoanRecommendations = (
  recommendations: CarRecommendation[],
  modelDetails: CarModelBasicDetailsResponse,
): CarModelDetailsResponse => {
  const carModelVariantLoans =
    recommendations.find((r) => r.id === modelDetails.id)?.variants || []
  return {
    ...modelDetails,
    variants: carModelVariantLoans.map((v) => ({
      ...v,
      ...modelDetails.variants.filter((c) => c.id === v.id)[0],
    })),
  }
}

const updateCarModelDetailsWithLoanInfo = (
  recommendations: CarRecommendation[],
  carModelDetails: CarModelBasicDetailsResponse,
  setCarModelDetails: (data: CarModelDetailsResponse) => void,
) => {
  carModelDetails &&
    setCarModelDetails(
      mergeModelDetailsWithLoanRecommendations(
        recommendations,
        carModelDetails,
      ),
    )
}

export const handleRecommendationsAndCarModelDetailsUpdate =
  (
    setRecommendations: (data: CarRecommendation[] | []) => void,
    setCarModelDetails: (data: CarModelDetailsResponse) => void,
  ) =>
  ([recommendationsResponse, carModelDetailsResponse]: any) => {
    const recommendations = recommendationsResponse?.carRecommendations || []
    const carModelDetails = carModelDetailsResponse

    setRecommendations(recommendations)
    updateCarModelDetailsWithLoanInfo(
      recommendations,
      carModelDetails,
      setCarModelDetails,
    )
  }

export const getFilteredRecommendationNeighboringCurrentModel = (
  carRecommendations: CarRecommendation[],
  currentModelName: string,
) => {
  const carArrLength = carRecommendations?.length ?? 0
  if (carArrLength < 1) return []

  const carArrMaxIndex = carRecommendations.length - 1
  const selectedCarModelName = currentModelName
  const selectedCarModelIndex = carRecommendations.findIndex(
    (item: any) => item.model === selectedCarModelName,
  )

  const maxRenderedLength = 10
  const halfOfMaxRenderedLength = maxRenderedLength / 2

  if (carArrLength <= maxRenderedLength + 1) {
    // +1 because there is 1 selected car
    // ===== HANDLER WHEN AVAILABLE LENGTH IS LESS THAN MAX RENDERED LENGTH =====
    const listWithoutSelectedModel = carRecommendations.filter(
      (item: any) => item.model !== selectedCarModelName,
    )
    return listWithoutSelectedModel
  } else if (selectedCarModelIndex < halfOfMaxRenderedLength) {
    // ===== HANDLER WHEN HAVING CAR AMOUNT WITH LOWER PRICE IS LESS THAN HALF MAX RENDERED LENGTH =====
    const maxPossibleCarAmountWithLowerPrice = Math.abs(
      // use abs() to prevent negative value
      selectedCarModelIndex % maxRenderedLength,
    )
    const difference =
      halfOfMaxRenderedLength - maxPossibleCarAmountWithLowerPrice
    const listWithLowerPrice = carRecommendations.slice(
      Math.max(
        0, // prevent negative value
        selectedCarModelIndex - halfOfMaxRenderedLength,
      ),
      selectedCarModelIndex,
    )
    const listWithHigherPrice = carRecommendations.slice(
      selectedCarModelIndex + 1,
      selectedCarModelIndex + halfOfMaxRenderedLength + 1 + difference,
    )
    return listWithLowerPrice.concat(listWithHigherPrice)
  } else if (carArrMaxIndex - selectedCarModelIndex < halfOfMaxRenderedLength) {
    // ===== HANDLER WHEN HAVING CAR AMOUNT WITH HIGHER PRICE IS LESS THAN HALF MAX RENDERED LENGTH =====
    const maxPossibleCarAmountWithHigherPrice = Math.abs(
      // use abs() to prevent negative value
      (selectedCarModelIndex - carArrMaxIndex) % maxRenderedLength,
    )
    const difference =
      halfOfMaxRenderedLength - maxPossibleCarAmountWithHigherPrice
    const listWithLowerPrice = carRecommendations.slice(
      Math.max(
        0, // prevent negative value
        selectedCarModelIndex - halfOfMaxRenderedLength - difference,
      ),
      selectedCarModelIndex,
    )
    const listWithHigherPrice = carRecommendations.slice(
      selectedCarModelIndex + 1,
      selectedCarModelIndex + halfOfMaxRenderedLength + 1,
    )
    return listWithLowerPrice.concat(listWithHigherPrice)
  } else {
    const listWithLowerPrice = carRecommendations.slice(
      selectedCarModelIndex - halfOfMaxRenderedLength,
      selectedCarModelIndex,
    )
    const listWithHigherPrice = carRecommendations.slice(
      selectedCarModelIndex + 1,
      selectedCarModelIndex + halfOfMaxRenderedLength + 1,
    )
    return listWithLowerPrice.concat(listWithHigherPrice)
  }
}

export const fetchAllCarModelsFromCurrentCity = async (
  cityCodeValue?: string,
  cityIdValue?: string,
  beforeFetchApiHandler?: () => void,
  onSuccessFetchApiHandler?: (responseApi: any) => void,
  onFailedHandler?: (errorObject: any) => void,
) => {
  try {
    const params = new URLSearchParams()
    let urlParamCityCode = ''
    let urlParamCityId = ''
    if (cityCodeValue) {
      urlParamCityCode = cityCodeValue
    } else if (getCity().cityCode) {
      urlParamCityCode = getCity().cityCode as string
    }
    if (cityIdValue) {
      urlParamCityId = cityIdValue
    } else if (getCity().id) {
      urlParamCityId = getCity().id ?? ''
    }

    urlParamCityCode && params.append('city', urlParamCityCode)
    urlParamCityId && params.append('cityId', urlParamCityId)

    beforeFetchApiHandler && beforeFetchApiHandler()
    const response = await getCarCatalog('', { params })
    onSuccessFetchApiHandler && onSuccessFetchApiHandler(response)
  } catch (error) {
    onFailedHandler && onFailedHandler(error)
    ReportSentry(
      'handler car recommendations - fetchAllCarModelsFromCurrentCity',
      error,
    )
  }
}

export const getRecommendationsLowestInstallmentMainAsNumber = (
  recommendations: CarRecommendation,
) => {
  if (
    !!recommendations.variants[0]?.calculator?.lowestCalculator?.installment
  ) {
    return (
      recommendations.variants[0]?.calculator?.lowestCalculator?.installment ||
      0
    )
  } else if (recommendations.lowestMonthlyInstallment !== undefined) {
    return recommendations.lowestMonthlyInstallment || 0
  } else {
    const prices =
      recommendations.variants?.map((variant) => variant.monthlyInstallment) ||
      []
    return Math.min(...prices)
  }
}

export const getRecommendationsInstallmentHighestCalculatorAsNumber = (
  recommendations: CarRecommendation,
) => {
  if (
    !!recommendations.variants[0]?.calculator?.highestCalculator?.installment &&
    recommendations.variants[0]?.calculator?.highestCalculator?.installment
  )
    if (
      !!recommendations.variants[0]?.calculator?.highestCalculator?.installment
    ) {
      return (
        recommendations.variants[0]?.calculator?.highestCalculator
          ?.installment || 0
      )
    } else {
      return null
    }
}

export const getRecommendationsLowestDpMainAsNumber = (
  recommendations: CarRecommendation,
) => {
  if (
    !!recommendations.variants[0]?.calculator?.lowestCalculator
      ?.totalFirstPayment
  ) {
    return (
      recommendations.variants[0]?.calculator?.lowestCalculator
        ?.totalFirstPayment || 0
    )
  } else if (recommendations.lowestDpAmount !== undefined) {
    return recommendations.lowestDpAmount || 0
  } else {
    const prices = recommendations.variants?.map((variant) => variant.dpAmount)
    return Math.min(...prices)
  }
}

export const getRecommendationsDpHighestCalculatorAsNumber = (
  recommendations: CarRecommendation,
) => {
  if (
    !!recommendations.variants[0]?.calculator?.highestCalculator
      ?.totalFirstPayment
  ) {
    return (
      recommendations.variants[0]?.calculator?.highestCalculator
        ?.totalFirstPayment || 0
    )
  } else {
    return null
  }
}

export const getLowestInstallmentFromCalculatorData = (
  calculator?: SpecialRateListWithPromoTypeV3,
) => {
  if (!!calculator?.lowestCalculator?.installment) {
    return calculator?.lowestCalculator?.installment
  } else {
    return null
  }
}

export const getHighestInstallmentFromCalculatorData = (
  calculator?: SpecialRateListWithPromoTypeV3,
) => {
  if (!!calculator?.highestCalculator?.installment) {
    return calculator?.highestCalculator?.installment
  } else {
    return null
  }
}

export const getLowestDpFromCalculatorData = (
  calculator?: SpecialRateListWithPromoTypeV3,
) => {
  if (!!calculator?.lowestCalculator?.totalFirstPayment) {
    return calculator?.lowestCalculator?.totalFirstPayment
  } else {
    return null
  }
}

export const getHighestDpFromCalculatorData = (
  calculator?: SpecialRateListWithPromoTypeV3,
) => {
  if (!!calculator?.highestCalculator?.totalFirstPayment) {
    return calculator?.highestCalculator?.totalFirstPayment
  } else {
    return null
  }
}
